import React, {useCallback, useEffect, useState} from "react";
import PortfolioChart from "../chart/PortfolioChart";
import {useMediaQuery} from "@mui/material";
import json2mq from "json2mq";
import {useNavigate, useNavigation} from "react-router-dom";
import GoToSubscribeAlert from "../../../common/alert/GoToSubscribeAlert";
import {getPortfolioNetworthGraphDropdownAPI, getPortfolioNetwrothGraphAPI} from "../../../apihelper/portfolio";
import {INDICES_MESSAGE} from "../../../common/constant/SubscriptionMessage";
import {ReactComponent as NotificationIcon} from "../../../pages/account/profile/profile_icon/notification-icon.svg";

const MainContent = ({index, setLoginModalOpen}) => {
    const [selectedPortfolio, setSelectedPortfolio] = React.useState(index === "Crypto" ? 80 : index);
    const [chartData, setChartData] = React.useState({});
    const [roiData, setRoiData] = React.useState({});
    const [dropdownOptions, setDropdownOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loginStatus, setLoginStatus] = useState(false);
    const [isEligible, setIsEligible] = useState(false);
    const [goToSubscribeAlertOpen, setGoToSubscribeAlertOpen] = useState(false);
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );

    const mediaQueryVar2 = useMediaQuery(
        json2mq({
            minWidth: 600,
        })
    );
    const handleLoginStatus = useCallback(() => {
        // Hide graph from logged out user.
        setLoginStatus(true)
        // setLoginStatus(userData ? true : false);
    }, []);

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const response = await getPortfolioNetworthGraphDropdownAPI();
                const options = response?.data || [];

                const matchedOption = options.find((option) => option.name.toLowerCase() === index.toLowerCase());

                let selectedPortfolioId;

                if (matchedOption?.id) {
                    selectedPortfolioId = matchedOption.id;
                } else {
                    selectedPortfolioId = index === "Crypto" ? 80 : index;
                }

                setDropdownOptions(options);
                setSelectedPortfolio(selectedPortfolioId);
            } catch (error) {
                console.error("Error fetching dropdown options:", error);
            }
        };

        fetchDropdownOptions();
    }, [index]); 

    const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);


    const fetchData = useCallback(async () => {

        // Hide graph from logged out user.
        // if (!userData) {
        //     setLoading(false);
        //     setIsEligible(false);
        //     return;
        // }
        console.log("USer data")
        try {
            setLoading(true);
            const response = await getPortfolioNetwrothGraphAPI(userData);
            if (response.ok) {
                const data = await response.json();
                setIsEligible(true);

                if (Array.isArray(data)) {
                    const processedData = processApiData(data);

                    setChartData(processedData.chartData);
                    setRoiData(processedData.roiData);
                    setDropdownOptions(processedData.dropdownOptions);

                    const matchedOption = processedData.dropdownOptions.find(
                        (option) => option.name.toLowerCase() === index.toLowerCase()
                    );
                    setSelectedPortfolio(matchedOption?.id || processedData.firstPortfolioId);

                    setLoading(false);
                } else {
                    setLoading(false)
                    // setIsEligible(false);
                    console.error("Error: Unexpected data structure");
                }
            } else {
                setLoading(false)
                console.error("Error: Unexpected response code", response.status);
            }
            
        } catch (err) {
            // const statusCode = err.message.match(/\d+/)?.[0];
            // if (statusCode === 403) {
            //     setIsEligible(false);
            // }
            setLoading(false);
            console.error("Error fetching data:", err);
        }
    }, [loginStatus, index]);

    React.useEffect(() => {
        handleLoginStatus();
        fetchData();
    }, [index]);

    const processApiData = (results) => {
        const data = {};
        const roiData = {};
        const dropdownOptions = [];
        const uniquePortfolios = new Set();
        let firstPortfolioId = null;

        results.forEach((row) => {
            const formattedDate = new Date(row.roi_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
            });

            if (!data[row.portfolio_type_id]) {
                data[row.portfolio_type_id] = {
                    dates: [],
                    values: [],
                };
            }

            data[row.portfolio_type_id].dates.push(formattedDate);
            data[row.portfolio_type_id].values.push(parseFloat((row.net_worth / 2500).toFixed(1)));

            roiData[row.portfolio_type_id] = {
                full_name: row.full_name || "Portfolio",
                portfolio_name: row.portfolio_name || "Portfolio",
                one_day_roi: row.one_day_roi || "NA",
                seven_day_roi: row.seven_day_roi || "NA",
                thirty_day_roi: row.thirty_day_roi || "NA",
                ninety_day_roi: row.ninety_day_roi || "NA",
            };

            if (!uniquePortfolios.has(row.portfolio_type_id)) {
                uniquePortfolios.add(row.portfolio_type_id);

                if (!firstPortfolioId) {
                    firstPortfolioId = row.portfolio_type_id;
                }

                const displayName = `${row.portfolio_name || "Unknown Portfolio"}`;
                dropdownOptions.push({
                    id: row.portfolio_type_id,
                    name: displayName,
                });
            }
        });

        return {
            chartData: data,
            roiData,
            dropdownOptions,
            firstPortfolioId,
        };
    };

    const handlePortfolioChange = (event) => {
        const selectedPortfolioId = event.target.value;
        const selectedOption = dropdownOptions.find((option) => option.id === selectedPortfolioId);
        if (!loginStatus || !isEligible) {
            handleGraphAccess();
        } else {
            if (selectedOption) {
                setSelectedPortfolio(selectedOption.id);
                navigate(`/indices/${selectedOption.name}-index`);
                setLoading(true);
            }
        }
    };

    const handleGraphAccess = () => {
        if (!loginStatus) {
            const originalUrl = window.location.pathname + window.location.search;
            sessionStorage.setItem("originalUrl", originalUrl);
            setLoginModalOpen(true);
        } else {
            setGoToSubscribeAlertOpen(true);
        }
    };

    return (
        <div>
            <div
                className="portfolio-networth-container"
                style={{
                    padding: mediaQueryVar ? "30px" : "10px",
                    background: "#fff",
                    borderRadius: "15px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                    marginTop: "30px",
                    marginLeft: mediaQueryVar ? "340px" : "0",
                    marginRight: mediaQueryVar ? "50px" : "0",
                    maxWidth: mediaQueryVar ? "75%" : "100%",
                }}
            >
                <div style={{display:"flex", gap:"15px"}}>
                {/* Dropdown for portfolio selection */}
                <div className="dropdown-container" style={{marginBottom: "20px"}}>
                    <label
                        htmlFor="portfolio-select"
                        style={{marginRight: "10px", fontWeight: "bold", fontSize: "16px", color: "#4A4A4A"}}
                    >
                        Select Index:
                    </label>
                    <select
                        id="portfolio-select"
                        value={selectedPortfolio}
                        onChange={handlePortfolioChange}
                        style={{
                            padding: "10px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            background: "#fff",
                            border: "1px solid #ddd",
                            color: "#4A4A4A",
                        }}
                    >
                        {dropdownOptions.length === 0 ? (
                            <option value="80">{index === "Crypto" ? "Memecoin" : index}</option>
                        ) : (
                            dropdownOptions.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))
                        )}
                    </select>
                </div>
                
                                   <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>
                                    </div>

                <div
                    style={{
                        marginBottom: "20px",
                        overflow: "hidden",
                        position: "relative",
                        border: "2px solid #7e7ef7",
                        height: mediaQueryVar ? "450px" : "300px",
                        borderRadius: "20px",
                        padding: "10px",
                    }}
                >
                    {loading || !selectedPortfolio ? (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                                color: "#7e7ef7",
                            }}
                        >
                            {/* Loading spinner */}
                            <div
                                style={{
                                    border: "4px solid rgba(0, 0, 0, 0.1)",
                                    borderTop: "4px solid #7e7ef7", // Spinner color
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                    marginLeft: "50%",
                                    animation: "spin 1s linear infinite",
                                }}
                            />
                            <p
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    marginTop: "10px",
                                }}
                            >
                                Rendering {index === "Crypto" ? "Memecoin  " : index} Index...
                            </p>
                        </div>
                    ) : (
                        <div className="h-100 position-relative">
                            {/* Graph Component */}
                            <div className={`h-100 graph-container blurred}`}>
                                <PortfolioChart
                                    chartData={chartData}
                                    selectedPortfolio={selectedPortfolio}
                                    roiData={roiData}
                                    isLoggedIn={isEligible && loginStatus}
                                />

                                {(!isEligible || !loginStatus) && (
                                    <div className="lock-overlay" onClick={() => handleGraphAccess()}>
                                        <img src={window.constants.asset_path + "/images/lock.svg"} />
                                        <br />
                                        {!loginStatus ? (
                                            <h6 className="mt-4">Subscribe now to Access Crypto Indices</h6>
                                        ) : !isEligible ? (
                                            <h6 className="mt-4">{INDICES_MESSAGE()}</h6>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {/* ROI Summary */}
                {!loading && selectedPortfolio ? (
                    mediaQueryVar2 ? (
                        roiData[selectedPortfolio] ? (
                            <div
                                className="roi-summary-container"
                                style={{
                                    marginTop: "20px",
                                    border: "1px solid #b6b6b6",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                <table
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        textAlign: "center",
                                    }}
                                >
                                    <thead style={{backgroundColor: "#f8f8f8", fontWeight: "bold"}}>
                                        <tr>
                                            <th
                                                style={{
                                                    padding: "0px",
                                                    color: "#727376",
                                                    borderRight: "1px solid #b6b6b6",
                                                    background: "white",
                                                    height: "100%",
                                                }}
                                            >
                                                <h6
                                                    style={{
                                                        textAlign: "center",
                                                        height: "60px",
                                                        border: "none",
                                                        padding: "15px 12px 15px 12px",
                                                        boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                                                        fontWeight: "400",
                                                    }}
                                                />
                                            </th>
                                            <th
                                                style={{
                                                    padding: "0px",
                                                    borderRight: "1px solid #b6b6b6",
                                                    background: "white",
                                                }}
                                            >
                                                <h6
                                                    style={{
                                                        textAlign: "center",
                                                        color: "#727376",
                                                        height: "60px",
                                                        border: "none",
                                                        padding: "15px 12px 15px 12px",
                                                        boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    Last 1 Day
                                                </h6>
                                            </th>
                                            <th
                                                style={{
                                                    padding: "0px",
                                                    borderRight: "1px solid #b6b6b6",
                                                    background: "white",
                                                }}
                                            >
                                                <h6
                                                    style={{
                                                        textAlign: "center",
                                                        color: "#727376",
                                                        height: "60px",
                                                        border: "none",
                                                        padding: "15px 12px 15px 12px",
                                                        boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    Last 7 Days
                                                </h6>
                                            </th>
                                            <th
                                                style={{
                                                    padding: "0px",
                                                    borderRight: "1px solid #b6b6b6",
                                                    background: "white",
                                                }}
                                            >
                                                <h6
                                                    style={{
                                                        textAlign: "center",
                                                        color: "#727376",
                                                        height: "60px",
                                                        border: "none",
                                                        padding: "15px 12px 15px 12px",
                                                        boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    Last 30 Days
                                                </h6>
                                            </th>
                                            <th
                                                style={{
                                                    padding: "0px",
                                                    borderRight: "1px solid #b6b6b6",
                                                    background: "white",
                                                }}
                                            >
                                                <h6
                                                    style={{
                                                        textAlign: "center",
                                                        color: "#727376",
                                                        height: "60px",
                                                        border: "none",
                                                        padding: "15px 12px 15px 12px",
                                                        boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    Last 90 Days
                                                </h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={!isEligible || !loginStatus ? "blurred" : ""}>
                                        <tr>
                                            <td style={{padding: "10px", borderRight: "1px solid #b6b6b6"}}>
                                                <a
                                                    href={`https://investing.crowdwisdom.live/portfolio/${roiData[selectedPortfolio]?.full_name}`}
                                                    aria-label={`Link to ${roiData[selectedPortfolio]?.portfolio_name} Index Performance`}
                                                    style={{
                                                        color: "#1a73e8",
                                                        textDecoration: "underline",
                                                    }}
                                                >
                                                    {roiData[selectedPortfolio]?.portfolio_name} Index Performance
                                                </a>
                                            </td>
                                            <td style={{padding: "10px", borderRight: "1px solid #b6b6b6"}}>
                                                {roiData[selectedPortfolio]?.one_day_roi}
                                            </td>
                                            <td style={{padding: "10px", borderRight: "1px solid #b6b6b6"}}>
                                                {roiData[selectedPortfolio]?.seven_day_roi}
                                            </td>
                                            <td style={{padding: "10px", borderRight: "1px solid #b6b6b6"}}>
                                                {roiData[selectedPortfolio]?.thirty_day_roi}
                                            </td>
                                            <td style={{padding: "10px"}}>
                                                {roiData[selectedPortfolio]?.ninety_day_roi}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) : null
                    ) : roiData[selectedPortfolio] ? (
                        <div
                            className="index"
                            style={{
                                border: "2px solid #ececec",
                                borderRadius: "10px",
                                padding: "15px",
                                maxWidth: "300px",
                                fontFamily: "Satoshi, sans-serif",
                                width: "100%",
                                margin: "5px auto",
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                                backgroundColor: "#fff",
                            }}
                        >
                            <p
                                style={{
                                    textAlign: "center",
                                    color: "#007BFF",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    marginTop: 0,
                                    marginBottom: "5px",
                                }}
                            >
                                <a
                                    href={`https://investing.crowdwisdom.live/portfolio/${roiData[selectedPortfolio]?.full_name}`}
                                    style={{
                                        color: "#1a73e8",
                                        textDecoration: "underline",
                                    }}
                                >
                                    {roiData[selectedPortfolio]?.portfolio_name} Index Performance
                                </a>
                            </p>

                            {["one_day_roi", "seven_day_roi", "thirty_day_roi", "ninety_day_roi"].map(
                                (roiKey, index) => (
                                    <div
                                        key={index}
                                        className="index"
                                        style={{
                                            padding: "10px 0",
                                            borderBottom: "1px solid #000",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span style={{color: "#606060", fontSize: "16px"}}>
                                            {`Last ${
                                                index === 0 ? "1" : index === 1 ? "7" : index === 2 ? "30" : "90"
                                            } Days`}
                                        </span>
                                        <span
                                            style={{
                                                color: "#007BFF",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                            className={!isEligible || !loginStatus ? "blurred" : ""}
                                        >
                                            {roiData[selectedPortfolio]?.[roiKey]}
                                        </span>
                                    </div>
                                )
                            )}
                        </div>
                    ) : null
                ) : null}
            </div>
            {goToSubscribeAlertOpen && <GoToSubscribeAlert onClose={setGoToSubscribeAlertOpen} />}
        </div>
    );
};

export default MainContent;
