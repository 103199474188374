import React, {useEffect, useState} from "react";
import Header2 from "../../common/header/Header2";
import {useLocation, useNavigate} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import {ReactComponent as NotificationIcon} from "../../pages/account/profile/profile_icon/notification-icon.svg";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";

import "./subscription.css";
import data from "./subsciption.json";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoginPopUp from "../loginpopup";
import GoogleLoginHandler from "../../common/hooks/GoogleLoginHandler";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";

const Subscription = () => {
    const navigate = useNavigate();
     const mediaQueryVar = useMediaQuery(
          json2mq({
            minWidth: 900,
          }),
        );
    const [isLoginModalOpen, setLoginModalOpen] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const location = useLocation();
    const loc = location.pathname;
    const userData = localStorage.getItem("cw_portfolio_user");

    const handleBackClick = () => {
        navigate(-1);
    };
    const handleModalClose = () => {
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };
    const handleCheckout = async (price_id, email) => {
        try {
            const response = await fetch("https://investing.crowdwisdom.live/create-checkout-session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({price_id, email}),
            });

            const session = await response.json();

            if (session.error) {
                console.error("Server Error: ", session.error);
                alert(`Error: ${session.error}`);
                return;
            }

            if (session.url) {
                window.location.href = session.url;
            }
        } catch (error) {
            console.error("Error during checkout: ", error);
        }
    };

    const onSelectAmount = (price_id) => {
        const userData = localStorage.getItem("cw_portfolio_user");
        if (!userData) {
            const originalUrl = "/subscriptions";
            sessionStorage.setItem("originalUrl", originalUrl);
            window.history.pushState({}, "", "/login");
            localStorage.setItem("isPopupManuallyOpened", "true");
            setLoginModalOpen(true);
            return;
        } else {
            const userData_full = JSON.parse(userData);

            const emailId = userData_full.email_id;
            handleCheckout(price_id, emailId); // Call handleCheckout with the selected amount
        }
    };
    useEffect(() => {
        const userData = localStorage.getItem("cw_portfolio_user");
        setIsLoggedIn(userData ? true : false);
    }, []);

    // useEffect(() => {
    //         const search = window.location.search;
    //         const params = new URLSearchParams(search);

    //         if (params) {
    //             const googleLoginCode = params.get("code");
    //             if (googleLoginCode) {

    //                 completeGoogleLogin(googleLoginCode).then((data) => {

    //                     if (data.code === "200") {

    //                         localStorage.setItem("cw_portfolio_user", JSON.stringify(data.data));
    //                         const portfolio_userId = localStorage.getItem("portfolio_userId");

    //                         if (portfolio_userId) {

    //                             window.location.replace(`${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`);
    //                             localStorage.removeItem("portfolio_userId");
    //                         } else {
    //                             if (data.data.portfolio_form_redirect === "yes") {

    //                                 window.location.replace(`${process.env.REACT_APP_HOME_URL}profile`);
    //                             } else {
    //                                 window.location.replace(`${process.env.REACT_APP_HOME_URL}home`);

    //                             }
    //                         }
    //                     } else {
    //                         console.log("GOOGLE LOGIN FAILED");

    //                     }
    //                 });
    //             }
    //         }
    //     });

    const stripePriceId = {
        plan_2: "price_1QwbqlKAXtGoEI6mmRIKdGnh",
        plan_3: "price_1QwbrDKAXtGoEI6mlEMXFJWd",
    };

    const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);

    return (
        <div className="d-flex row  w-100 m-0">
            <Header2>
                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <div onClick={handleBackClick}>
                    <ArrowBackIcon />
                </div>
                <div className="notification-section position-relative" onClick={() => navigate("/alerts")} style={{marginRight:"10px"}}>
                  <NotificationIcon className="notification-icon fa-2x" />
                         <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                 {unreadAlerts}
                           </span>
                     </div>
                     </div>
            </Header2>

            <div className="subscription-container col p-0 d-flex justify-content-center" style={{maxWidth: "100%"}}>
                <div className="subscription-container-main">
                    <div className="subscription-container-2">
                        <div className="subscription-column" style={{display:"flex", gap:"15px"}}>
                            <h2 className="ml-2">Subscription</h2>
                            {mediaQueryVar? (
                           <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                           <NotificationIcon className="notification-icon fa-2x" />
                                  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                          {unreadAlerts}
                                    </span>
                                             </div>
                            ): null}
                            
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table">
                            <thead className="text-center">
                                <tr className="header-button">
                                    <th></th>
                                    <th className="p-0">
                                        <div className="subscription-column custom-col">
                                            <p className="text-primary  py-1 px-0 fw-bold text-center" readonly>
                                                Free Plan
                                            </p>
                                            <button
                                                className="subscription-button subscription-button-primary"
                                                onClick={() => setLoginModalOpen(true)}
                                            >
                                                Start Now
                                            </button>
                                        </div>
                                    </th>
                                    <th className="p-0">
                                        <div className="subscription-column custom-col">
                                            <p className="text-primary  py-1 px-0 fw-bold text-center" readonly>
                                                Smart Plan
                                            </p>
                                            <button
                                                className="subscription-button subscription-button-primary"
                                                onClick={() => onSelectAmount(stripePriceId.plan_2)}
                                            >
                                                $20/ 2 Months
                                            </button>
                                        </div>
                                    </th>
                                    <th className="p-0">
                                        <div className="subscription-column custom-col">
                                            <p className="text-primary  py-1 px-0 fw-bold text-center" readonly>
                                                Wise Plan
                                            </p>
                                            <button
                                                className="subscription-button subscription-button-primary"
                                                onClick={() => onSelectAmount(stripePriceId.plan_3)}
                                            >
                                                $35/ 2 Months
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="top-row">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {Object.entries(data).map(([category, features], index) => (
                                    <React.Fragment key={index}>
                                        {/* Category Row */}
                                        <tr>
                                            <td className="fw-bold text-start">{category}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        {/* Feature Rows */}
                                        {features.map((feature, fIndex) => (
                                            <tr key={fIndex}>
                                                <td className="text-start">{feature.value}</td>
                                                {["col-1", "col-2", "col-3"].map((col, cIndex) => (
                                                    <td key={cIndex}>
                                                        {feature[col] === "true" ? (
                                                            <CheckIcon className="text-success" />
                                                        ) : feature[col] === "false" ? (
                                                            <CloseOutlinedIcon className="text-danger" />
                                                        ) : (
                                                            <span>{feature[col]}</span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                                <tr className="bottom-row">
                                    <td></td>
                                    <td>
                                        {!userData && (
                                            <button
                                            className="subscription-button subscription-button-primary"
                                            onClick={() => setLoginModalOpen(true)}
                                        >
                                            Free
                                        </button>
                                        )}
                                        
                                    </td>
                                    <td>
                                        <button
                                            className="subscription-button subscription-button-primary"
                                            onClick={() => onSelectAmount(stripePriceId.plan_2)}
                                        >
                                            Buy Now
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="subscription-button subscription-button-primary"
                                            onClick={() => onSelectAmount(stripePriceId.plan_3)}
                                        >
                                            Buy Now
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: "fixed",
                    bottom: "0",
                    backgroundColor: "white",
                    width: "105%",
                    zIndex: "100",
                    marginBottom: "0px",
                }}
                className="mobile-view"
            >
                <BottomNavigation showLabels>
                    <BottomNavigationAction
                        label="Top Portfolios"
                        onClick={() => navigate("/portfolio-gallery")}
                        icon={
                            <img
                                src={loc === "/portfolio-gallery" ? "/images/dashboardS.svg" : "/images/dashboardU.svg"}
                            />
                        }
                    />
                    <BottomNavigationAction
                        label="My Portfolios"
                        onClick={() => {
                            navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
                        }}
                        icon={
                            <img src={loc === "/my-portfolio" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"} />
                        }
                    />
                    <BottomNavigationAction
                        label="Crypto Screener"
                        onClick={() => {
                            navigate("/crypto-screener");
                        }}
                        icon={
                            <img
                                src={
                                    loc === "/subscriptions/screener360" ? "/images/dollarS.svg" : "/images/dollarU.svg"
                                }
                                width={24}
                                height={24}
                                alt="Icon"
                                style={{marginBottom: "-3px"}}
                            />
                        }
                    />
                </BottomNavigation>
            </div>
            {isLoginModalOpen && !isLoggedIn && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen}
                    onClose={handleModalClose}
                />
            )}
            <GoogleLoginHandler redirectUrl={location.pathname} />
        </div>
    );
};

export default Subscription;
