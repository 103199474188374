import React from "react";
import Box from "@mui/material/Box";
import VerifiedIcon from "@mui/icons-material/Verified";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import LoginPopUp from "../loginpopup.js";
import IconButton from "@mui/material/IconButton";
import {ButtonGroup, BottomNavigation, Paper, Chip} from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AddIcon from "@mui/icons-material/Add";
import BarChartIcon from "@mui/icons-material/BarChart";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CreatableSelect from "react-select/creatable";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import {Tabs, Tab} from "@mui/material";
import {Helmet} from "react-helmet";
import TableRow from "@mui/material/TableRow";
import {Button, Grid, TextField, Card, Checkbox} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import "../home/home.css";
import {ReactComponent as NotificationIcon} from "../../pages/account/profile/profile_icon/notification-icon.svg";

import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import {completeGoogleLogin} from "../../apihelper/login";
import CircularProgress from "@mui/material/CircularProgress";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import PortFolioGallery from "../home/PortFolioGallery.js";
import PracticePortfolio from "../home/PracticePortfolio.js";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router-dom";
import {getTotalunit, getFetchdropdown} from "../../apihelper/cryptocurrencylist";

import Dialog from "@mui/material/Dialog";
const drawerWidth = 292;
//
export default function Home(props) {
    //console.log("Props: ",props);
    const showPopup = props.showPopup;

    // Check if referral ID exists in URL
    const urlParams = new URLSearchParams(window.location.search);
    let referrerId = urlParams.get("ref");

    // If referral ID is not in the URL, check localStorage
    if (!referrerId) {
        referrerId = localStorage.getItem("referrerId");
    }

    // Process the referrerId during registration/login
    if (referrerId) {
        console.log(`Referred by user: ${referrerId}`);
        // You can now send this referral ID to your backend during the login or signup process
    }

    const [selectedmenu, setselectedmenu] = useState("");

    const [options, setOptions] = useState([]);
    const [totalUnits, setTotalUnits] = useState(0);

    const [Usablewallet, setUsablewallet] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [user_role, setuser_role] = useState("");
    const location = useLocation();
    const queryString = location.search; // This includes the entire query string starting with '?'
    console.log("Query String: ", queryString);

    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const {window1} = props;
    const [alignment, setAlignment] = React.useState("left");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [showloginpage, setShowloginpage] = useState(false);

    const loc = location.pathname;

    const [filter, setFilter] = useState("");

    const [loadingForLogin, setLoadingForLogin] = useState(false);

    const [loaderForSymbolLink, setLoaderForSymbolLink] = useState(false);

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );

    const mediaQueryVar5 = useMediaQuery(
        json2mq({
            minWidth: 1900,
        })
    );
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
            <SidebarMenuList />
        </div>
    );

    useEffect(() => {
        setShowloginpage(true);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        // console.log(params);
        if (params) {
            const googleLoginCode = params.get("code");
            if (googleLoginCode) {
                setLoadingForLogin(true);
                completeGoogleLogin(googleLoginCode).then((data) => {
                    // console.log(data);
                    if (data.code == "200") {
                        // console.log(data.message);
                        localStorage.setItem("cw_portfolio_user", JSON.stringify(data.data));
                        const portfolio_userId = localStorage.getItem("portfolio_userId");
                        // console.log("portfolio_UserID: ", portfolio_userId);
                        if (portfolio_userId) {
                            // console.log("login userId: ",portfolio_userId);
                            //  navigate(`portfolio/${portfolio_userId}`, { replace: true });
                            window.location.replace(`${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`);
                            localStorage.removeItem("portfolio_userId");
                        } else {
                            if (data.data.portfolio_form_redirect === "yes") {
                                //navigate("/profile", { replace: true });
                                window.location.replace(`${process.env.REACT_APP_HOME_URL}profile`);
                            } else {
                                window.location.replace(`${process.env.REACT_APP_HOME_URL}home`);
                                //navigate("/cryptocurrencylist", { replace: true });
                            }
                        }
                    } else {
                        console.log("GOOGLE LOGIN FAILED");
                        // TODO
                        // pop up the error
                    }
                });
            } else {
                setShowloginpage(false);
            }
        }
    });

    var rows = [],
        i = 0,
        len = 15;
    while (++i <= len) rows.push(i);

    const container = window1 !== undefined ? () => window1().document.body : undefined;
    const [selectedButton, setSelectedButton] = useState("CoinPrices");
    const [isTableView, setIsTableView] = useState(true);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    useEffect(() => {
        if (showPopup) {
            setLoginModalOpen(true); // Open the login modal
            localStorage.setItem("isPopupManuallyOpened", "true");
        }
    }, [showPopup]);

    const handleLoginClick = () => {
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
    };

    const handleModalClose = () => {
        // Close the modal and reset the URL to the current page
        setLoginModalOpen(false);
        navigate("/portfolio-gallery", {replace: true});
    };

    const handleButtonClick = (buttonName) => {
        // If the same button is clicked again, don't toggle the view
        if (selectedButton === buttonName) {
            return;
        }

        // Toggle the view based on the clicked button
        setSelectedButton(buttonName);
        setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
    };

    useEffect(() => {
        if (selectedButton === "PortfolioGallery") {
            navigate("/portfolio-gallery");
            setFilter(""); // Reset the filter state when selectedButton changes to PortfolioGallery
        }
    }, [selectedButton]);

    useEffect(() => {
        if (selectedButton === "CoinPrices") {
            navigate("/crypto-screener");
            setFilter(""); // Reset the filter state when selectedButton changes to PortfolioGallery
        }
    }, [selectedButton]);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        // Check if the query string starts with ?login
        if (queryString.startsWith("?login") && !userData) {
            // Call the handleLoginClick function after 6 seconds
            setTimeout(() => {
                handleLoginClick();
            }, 6000);
        }
    }, [queryString]);

    const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);


    return (
        <>
            {mediaQueryVar === true ? (
                <Box
                    sx={{
                        flexDirection: mediaQueryVar === false ? "column" : "",

                        display: "flex",
                        height: "100vh",
                    }}
                    className="maindiv"
                >
                    <Helmet>
                        <title>Best Crypto Portfolios</title>
                    </Helmet>
                    <CssBaseline />
                    <div className="windowheader">
                        <AppBar
                            position="fixed"
                            sx={{
                                width: {sm: `calc(100% - ${drawerWidth}px)`},
                                ml: {sm: `${drawerWidth}px`},
                            }}
                            style={{
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{mr: 2, display: {sm: "none"}}}
                                >
                                    <MenuIcon />
                                </IconButton>
                                {/* <Typography variant="h6" noWrap component="div"> */}
                                <img
                                    src="images/logo_with_bg.png"
                                    width={100}
                                    height={20}
                                    alt="CWLOGO"
                                    style={{margin: "10px", borderRadius: "5px"}}
                                />

                                {/* </Typography> */}
                            </Toolbar>
                        </AppBar>
                    </div>

                    <Box
                        component="nav"
                        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                        aria-label="mailbox folders"
                    >
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {xs: "block", sm: "none"},
                                "& .MuiDrawer-paper": {
                                    boxSizing: "border-box",
                                    width: drawerWidth,
                                    backgroundColor: "white",
                                    color: "#727376",
                                },
                            }}
                            // style={{ borderRadius:"100px"}}
                        >
                            {drawer}
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            // className={classes.border_radius}
                            sx={{
                                display: {xs: "none", sm: "block"},

                                "& .MuiDrawer-paper": {
                                    boxSizing: "border-box",
                                    width: drawerWidth,
                                    backgroundColor: "white",
                                    color: "#727376",
                                },
                            }}
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    {/* {loaderForSymbolLink===true && */}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: {sm: `calc(100% - ${drawerWidth}px)`},
                            backgroundColor: "#EDF1F6",
                            padding: "2vw",
                            flexDirection: "column",
                            minHeight: "100vh",
                        }}
                        className="maindiv"
                    >
                        {isLoginModalOpen && (
                            <LoginPopUp
                                isLoginModalOpen={isLoginModalOpen}
                                setLoginModalOpen={setLoginModalOpen} 
                                onClose={handleModalClose}
                            />
                        )}

                        {loadingForLogin && (
                            <div
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "30px",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                        {!loadingForLogin && (
                            <>
                                <div style={{display: "flex"}}>
                                    {mediaQueryVar === true ? (
                                        <Grid container spacing={2} alignItems="center" style={{paddingBottom: "0px"}}>
                                            <Grid
                                                item
                                                xs={12}
                                                md={9}
                                                className="CryptocurrencyListHeader"
                                                style={{display: "flex", alignItems: "center", paddingBottom: "0px"}}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    style={{
                                                        textAlign: mediaQueryVar === false ? "center" : "left",
                                                        marginTop: mediaQueryVar === false ? "60px" : "",
                                                    }}
                                                >
                                                    {selectedButton === "PortfolioGallery" && null}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <div style={{marginTop: "70px"}}>{/* Content for else condition */}</div>
                                    )}
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <Box
                                        sx={{
                                            backgroundColor: "#1877F2", // Background color for the container
                                            paddingTop: {xs: "10px", sm: "4px"}, // Padding inside the container
                                            paddingLeft: {xs: "0px", sm: "0px"},
                                            paddingRight: {xs: "0px", sm: "0px"},
                                            paddingBottom: {xs: "0px", sm: "4px"},
                                            borderRadius: "50px", // Border radius for the container
                                            display: "flex", // Make sure the box is flexible
                                            justifyContent: "flex-start", // Center the content
                                            alignItems: "center", // Center the content
                                            width: userData ? "475px" : "490px",
                                            height: {xs: "44px", sm: "48px"},
                                            border: "none",
                                            marginLeft: "17px",
                                            marginTop: "-10px",
                                        }}
                                    >
                                        <Tabs
                                            value={selectedButton}
                                            onChange={(event, newValue) => handleButtonClick(newValue)}
                                            aria-label="basic tabs example"
                                            sx={{
                                                alignSelf: mediaQueryVar === true ? "flex-start" : "",
                                                marginLeft: mediaQueryVar === true ? "2px" : "2px",
                                                borderRadius: "50px", // Adjust border radius for more rounded corners
                                                border: "none",
                                                "& .MuiTabs-indicator": {
                                                    display: "none",
                                                },
                                                display: "flex", // Make sure the tabs are displayed in a row
                                                overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                                            }}
                                        >
                                            <Tab
                                                label="Top Portfolios"
                                                value="PortfolioGallery"
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    bottom: "0px",
                                                    marginTop: "1.1px",
                                                    marginBottom: "0px",
                                                    marginLeft: "3px",
                                                    marginRight: "3px",
                                                    paddingBottom: "2px",
                                                    paddingTop: "0px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px",
                                                    minHeight: "20px",
                                                    lineHeight: {xs: "28px", sm: "36px"},
                                                    borderRadius:
                                                        selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
                                                    textTransform: "none",
                                                    fontSize: {xs: "13px", sm: "16px"},
                                                    fontStyle: "normal",
                                                    fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",

                                                    backgroundColor:
                                                        selectedButton === "PortfolioGallery"
                                                            ? "#FFFFFF"
                                                            : "transparent", // White background for selected tab
                                                    color:
                                                        selectedButton === "PortfolioGallery"
                                                            ? "#000000 !important"
                                                            : "#FFFFFF", // Black text for selected tab
                                                    border: "none", // Remove border for tabs
                                                    background:
                                                        selectedButton === "PortfolioGallery"
                                                            ? "#FFFFFF"
                                                            : "transparent", // Blue background for unselected tab
                                                }}
                                            />
                                            <Tab
                                                label={userData ? "My Portfolios" : "Boost Portfolio"} // Dynamic label
                                                value="AuditPortfolio"
                                                onClick={() => {
                                                    navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
                                                }}
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    bottom: "0px",
                                                    marginTop: "0px",
                                                    marginBottom: "0px",
                                                    marginLeft: "3px",
                                                    marginRight: "3px",
                                                    paddingBottom: "0px",
                                                    paddingTop: "0px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px",
                                                    minHeight: "20px",
                                                    lineHeight: {xs: "36px", sm: "36px"},
                                                    borderRadius: selectedButton === "AuditPortfolio" ? "50px" : "50px", // Rounded corners for selected tab
                                                    textTransform: "none",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: selectedButton === "AuditPortfolio" ? "700" : "500",
                                                    backgroundColor:
                                                        selectedButton === "AuditPortfolio" ? "#FFFFFF" : "transparent", // White background for selected tab
                                                    color:
                                                        selectedButton === "AuditPortfolio"
                                                            ? "#000000 !important"
                                                            : "#FFFFFF", // Black text for selected tab
                                                    border: "none", // Remove border for tabs
                                                }}
                                            />
                                            <Tab
                                                label="Crypto Screener"
                                                value="CoinPrices"
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    bottom: "0px",
                                                    marginTop: "0px",
                                                    marginBottom: "0px",
                                                    marginLeft: "3px",
                                                    marginRight: "3px",
                                                    paddingBottom: "0px",
                                                    paddingTop: "0px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px",
                                                    minHeight: "20px",
                                                    lineHeight: {xs: "36px", sm: "36px"},
                                                    borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
                                                    textTransform: "none",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: selectedButton === "CoinPrices" ? "700" : "500",

                                                    backgroundColor:
                                                        selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                                                    color:
                                                        selectedButton === "CoinPrices"
                                                            ? "#000000 !important"
                                                            : "#FFFFFF", // Black text for selected tab
                                                    border: "none", // Remove border for tabs
                                                    background:
                                                        selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
                                                }}
                                            />
                                        </Tabs>
                                    </Box>
                                    {selectedButton == "CoinPrices" && (
                                      <div style={{display:"flex"}}>  <TextField
                                            style={{
                                                width: "85%",
                                                borderRadius: "46px",
                                                marginTop: mediaQueryVar === true ? "" : "60px",
                                                fontFamily: "Satoshi, sans-serif",
                                                marginRight: "12px",
                                                border: " 1px #1877F2 solid",
                                                backgroundColor: "#fff",
                                                paddingTop: "0px",
                                                paddingBottom: "2px",
                                                paddingLeft: "12px",
                                                paddingRight: "30px",
                                                marginBottom: "10px",
                                                marginRight: "2%",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#9D9DA5",
                                                marginLeft: mediaQueryVar5 ? "5%" : "1%",
                                            }}
                                            className="cryptocurrencyListHeaderSearch"
                                            size="small"
                                            placeholder="Search Crypto"
                                            onChange={(e) => setFilter(e.target.value)}
                                            value={filter}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img
                                                            src={window.constants.asset_path + "/images/search.svg"}
                                                            width={20}
                                                            height={20}
                                                            alt="search Icon"
                                                        />
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    border: "none", // Remove the border from the input
                                                },
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "transparent", // Remove the border of the fieldset
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "transparent", // Remove the border on hover
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "transparent", // Remove the border when focused
                                                    },
                                                },
                                            }}
                                        />
                                        <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>
                                    </div>
                                    )}
                                    
                                </div>

                                <Box
                                    sx={{
                                        display: selectedButton === "PortfolioGallery" ? "flex" : "",
                                        width: "96.5%",

                                        border: "1px solid white",
                                        borderRadius: "10px",
                                        paddingTop: "25px",
                                        paddingLeft: "25px",
                                        paddingRight: "-50px",
                                        marginTop: "10px",

                                        marginLeft: "20px",
                                        marginRight: "20px",
                                        boxShadow: "0 2px 4px rgba(0,0,0,0)",
                                        backgroundColor: "white",
                                        overflow: "auto",
                                        // Hide the scrollbar
                                        "&::-webkit-scrollbar": {
                                            display: "none",
                                        },
                                        "-ms-overflow-style": "none",
                                        "scrollbar-width": "none",
                                    }}
                                >
                                    <div style={{display: "flex", marginBottom: "20px", flexDirection: "column"}}>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%",
                                                marginBottom: "20px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {selectedButton === "PortfolioGallery" && (
                                                <TextField
                                                    style={{
                                                        width: "25%",
                                                        borderRadius: "46px",
                                                        marginTop: mediaQueryVar ? "-6px" : "60px",
                                                        fontFamily: "Satoshi, sans-serif",
                                                        marginRight: "25px",
                                                        border: "1px #1877F2 solid",
                                                        backgroundColor: "white",
                                                        paddingTop: "0px",
                                                        paddingBottom: "2px",
                                                        paddingLeft: "12px",
                                                        paddingRight: "30px",
                                                        marginBottom: "5px",
                                                        marginLeft: "12%",
                                                    }}
                                                    size="small"
                                                    placeholder="Search Portfolios"
                                                    onChange={(e) => setFilter(e.target.value)}
                                                    value={filter}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <img
                                                                    src={
                                                                        window.constants.asset_path +
                                                                        "/images/search.svg"
                                                                    }
                                                                    width={20}
                                                                    height={20}
                                                                    alt="search Icon"
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                        style: {
                                                            border: "none", // Remove the border from the input
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "transparent", // Remove the border of the fieldset
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "transparent", // Remove the border on hover
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "transparent", // Remove the border when focused
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Grid>

                                        {selectedButton === "CoinPrices" ? (
                                            <>
                                                <PracticePortfolio filter={filter} onButtonClick={handleButtonClick} />
                                            </>
                                        ) : (
                                            <>
                                                <PortFolioGallery filter={filter} onButtonClick={handleButtonClick} />
                                            </>
                                        )}
                                    </div>
                                </Box>
                            </>
                        )}

                        {loaderForSymbolLink === true && (
                            <div>
                                <CircularProgress style={{position: "fixed", top: "40vh", left: "45vw"}} />
                            </div>
                        )}
                    </Box>

                    {/* } */}
                </Box>
            ) : (


                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100vh", // Ensures it takes at least the full viewport height
                        flexGrow: 1, // Allows the Box to grow and fill the available space
                        overflowY: "auto",
                        maxWidth: "100vw",
                    }}
                >
                    <Helmet>
                        <title>Best Crypto Portfolios</title>
                    </Helmet>
                    <CssBaseline />

                    <Box
                        component="nav"
                        sx={{
                            width: "100%", // Take full width on mobile
                        }}
                        aria-label="mailbox folders"
                    >
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            sx={{
                                "& .MuiDrawer-paper": {
                                    width: "280px", // Adjust width for mobile
                                    backgroundColor: "white",
                                    color: "#727376",
                                },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>

                    <div style={{backgroundColor: "white", position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000}}>
                        <Box
                            component="main"
                            sx={{
                                minHeight: "100vh",
                                flexGrow: 1,
                                backgroundColor: "white",
                                padding: "24px 2px 2vw 2px",
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                            className="maindiv"
                        >
                            {isLoginModalOpen && (
                                <LoginPopUp
                                    isLoginModalOpen={isLoginModalOpen}
                                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                                    onClose={handleModalClose}
                                />
                            )}

                            {loadingForLogin && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "30px",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            )}

                            {!loadingForLogin && (
                                <>
                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                        <div style={{display:"flex"}}>
                                    <div className="notification-section position-relative" style={{marginTop:"-5px"}}
                                    onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="end"
                                            onClick={handleDrawerToggle}
                                            sx={{display: {sm: "none"}, ml: "auto"}} // ml: 'auto' can be removed since flex does the job
                                        >
                                            <img
                                                src={window.constants.asset_path + "/images/menubar.svg"}
                                                alt="Menu Icon"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    marginRight: "17px",
                                                    marginBottom: "10px",
                                                    marginTop: "-10px",
                                                }}
                                            />
                                        </IconButton>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginTop: "0px",
                                            marginBottom: "5px",
                                        }}
                                    ></div>

                                    {selectedButton === "PortfolioGallery" ? (
                                        <TextField
                                            style={{
                                                width: "99%",
                                                borderRadius: "46px",
                                                marginTop: mediaQueryVar ? "" : "10px",
                                                fontFamily: "Satoshi, sans-serif",
                                                marginRight: "20px",
                                                border: "1px #b6b6b8 solid",
                                                backgroundColor: "white",
                                                paddingTop: "0px",
                                                paddingBottom: "2px",
                                                paddingLeft: "12px",
                                                paddingRight: "30px",
                                                marginBottom: "5px",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginLeft: "-8px",
                                            }}
                                            size="small"
                                            placeholder="Search Portfolios"
                                            onChange={(e) => setFilter(e.target.value)}
                                            value={filter}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img
                                                            width={13}
                                                            height={13}
                                                            src={window.constants.asset_path + "/images/search.svg"}
                                                            alt="search Icon"
                                                        />
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    border: "none", // Remove the border from the input
                                                },
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "transparent", // Remove the border of the fieldset
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "transparent", // Remove the border on hover
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "transparent", // Remove the border when focused
                                                    },
                                                },
                                            }}
                                        />
                                    ) : (
                                        <TextField
                                            style={{
                                                width: "73%",
                                                borderRadius: "46px",
                                                marginTop: mediaQueryVar ? "" : "-50px",
                                                fontFamily: "Satoshi, sans-serif",
                                                marginRight: "25px",
                                                border: "1px #b6b6b8 solid",
                                                backgroundColor: "white",
                                                paddingTop: "0px",
                                                paddingBottom: "2px",
                                                paddingLeft: "12px",
                                                paddingRight: "30px",
                                                marginBottom: "0px",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginLeft: "3px",
                                            }}
                                            size="small"
                                            placeholder="Search Crypto"
                                            onChange={(e) => setFilter(e.target.value)}
                                            value={filter}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img
                                                            width={13}
                                                            height={13}
                                                            src={window.constants.asset_path + "/images/search.svg"}
                                                            alt="search Icon"
                                                        />
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    border: "none", // Remove the border from the input
                                                },
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "transparent", // Remove the border of the fieldset
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "transparent", // Remove the border on hover
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "transparent", // Remove the border when focused
                                                    },
                                                },
                                            }}
                                        />
                                    )}

                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            marginBottom: "0px",
                                            marginTop: "-5px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: "#1877F2", // Background color for the container
                                                paddingTop: "3.105px", // Padding inside the container
                                                paddingLeft: "0px",
                                                paddingRight: "0px",
                                                paddingBottom: "3.105px",
                                                borderRadius: "38.042px", // Border radius for the container
                                                display: "inline-flex", // Make sure the box is flexible
                                                justifyContent: "center", // Center the content
                                                alignItems: "center", // Center the content
                                                width: userData ? "335px" : "340px", // Full width on extra small screens
                                                height: "39px",
                                                border: "none",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            <Tabs
                                                value={selectedButton}
                                                onChange={(event, newValue) => handleButtonClick(newValue)}
                                                aria-label="basic tabs example"
                                                sx={{
                                                    marginLeft: "0px",
                                                    "& .MuiTabs-indicator": {
                                                        display: "none",
                                                    },
                                                    display: "flex", // Make sure the tabs are displayed in a row
                                                    overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                                                }}
                                            >
                                                <Tab
                                                    label="Top Portfolios"
                                                    value="PortfolioGallery"
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        borderRadius: "20.962px",
                                                        minHeight: "31.5px",
                                                        height: "31.5px",
                                                        fontWeight:
                                                            selectedButton === "PortfolioGallery" ? "700" : "500",
                                                        marginLeft: "1px",
                                                        marginTop: "8px",
                                                        border: "1px solid black",
                                                        marginBottom: "-5px",
                                                        fontSize: "13px",
                                                        marginRight: "-15px",
                                                        backgroundColor:
                                                            selectedButton === "PortfolioGallery"
                                                                ? "#FFFFFF"
                                                                : "transparent", // White background for selected tab
                                                        color:
                                                            selectedButton === "PortfolioGallery"
                                                                ? "#000000 !important"
                                                                : "#FFFFFF", // Black text for selected tab
                                                        textTransform: "none",
                                                        border: "none",
                                                    }}
                                                />
                                                <Tab
                                                    label={userData ? "My Portfolios" : "Boost Portfolio"}
                                                    value="AuditPortfolio"
                                                    onClick={() => {
                                                        navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
                                                    }}
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        borderRadius: "20.962px",
                                                        minHeight: "31px",
                                                        height: "31px",
                                                        marginLeft: "10px",
                                                        marginTop: "8px",
                                                        border: "1px solid black",
                                                        fontWeight: selectedButton === "AuditPortfolio" ? "700" : "500",
                                                        marginRight: "5px",
                                                        fontSize: "13px",
                                                        paddingRight: "-8px",
                                                        textTransform: "none",
                                                        backgroundColor:
                                                            selectedButton === "AuditPortfolio"
                                                                ? "#FFFFFF"
                                                                : "transparent", // White background for selected tab
                                                        color:
                                                            selectedButton === "AuditPortfolio"
                                                                ? "#000000 !important"
                                                                : "#FFFFFF", // Black text for selected tab
                                                        border: "none", // Remove border for tabs
                                                    }}
                                                />
                                                <Tab
                                                    label="Screener"
                                                    value="CoinPrices"
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        borderRadius: "20.962px",
                                                        minHeight: "31px",
                                                        height: "31px",
                                                        marginLeft: "5px",
                                                        marginTop: "8px",
                                                        border: "1px solid black",
                                                        fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                                                        marginRight: "0px",
                                                        fontSize: "13px",
                                                        paddingRight: "-8px",
                                                        textTransform: "none",
                                                        backgroundColor:
                                                            selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                                                        color:
                                                            selectedButton === "CoinPrices"
                                                                ? "#000000 !important"
                                                                : "#FFFFFF", // Black text for selected tab
                                                        border: "none", // Remove border for tabs
                                                    }}
                                                />
                                            </Tabs>
                                        </Box>
                                    </Grid>

                                    {selectedButton === "CoinPrices" ? (
                                        <PracticePortfolio filter={filter} onButtonClick={handleButtonClick} />
                                    ) : (
                                        <PortFolioGallery filter={filter} onButtonClick={handleButtonClick} />
                                    )}
                                </>
                            )}
                            {loaderForSymbolLink === true && (
                                <div
                                    style={{
                                        position: "fixed",
                                        top: "40%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            )}
                        </Box>
                    </div>

                    {mediaQueryVar === false && (
                        <div
                            style={{
                                position: "fixed",
                                bottom: "0",
                                backgroundColor: "white",
                                width: "105%",
                                zIndex: "1000",
                                boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)", // Optional shadow for depth
                                marginLeft: "-8px",
                            }}
                        >
                            <BottomNavigation showLabels>
                                <BottomNavigationAction
                                    label={<span style={{ fontSize: "11px" }}>Best Portfolios</span>}
                                    onClick={() => {
                                        navigate("/portfolio-gallery");
                                    }}
                                    icon={
                                        <img
                                            src={
                                                loc === "/portfolio-gallery"
                                                     ? `${window.constants.asset_path}/images/portfolioS.svg`
                                                    : `${window.constants.asset_path}/images/portfolioU.svg`
                                            }
                                            width={20}
                                            height={20}
                                            alt="Icon"
                                        />
                                    }
                                />
                                 <BottomNavigationAction
                                    label={<span style={{ fontSize: "11px" }}>Portfolio Audit</span>}
                                    onClick={() => {
                                        navigate("/audit-my-portfolio");
                                    }}
                                    icon={
                                        <img
                                            src={
                                                loc === "/audit-my-portfolio"
                                                    ? `${window.constants.asset_path}/images/auditS.svg`
                                                    : `${window.constants.asset_path}/images/auditU.svg`
                                            }
                                            width={23}
                                            height={23}
                                            alt="Icon"
                                        />
                                    }
                                />
                                 <BottomNavigationAction
                                    label={<span style={{ fontSize: "11px" }}>Hidden Gems</span>}
                                    onClick={() => {
                                        navigate("/crypto-screener/hidden-gems");
                                    }}
                                    icon={
                                        <img
                                            src={
                                                loc === "/crypto-screener/hidden-gems"
                                                    ? `${window.constants.asset_path}/images/dashboardS.svg`
                                                    : `${window.constants.asset_path}/images/dashboardU.svg`
                                            }
                                            width={20}
                                            height={20}
                                            alt="Icon"
                                        />
                                    }
                                />
                                
                                <BottomNavigationAction
                                    label={<span style={{ fontSize: "11px" }}>Smart<br></br>Picks</span>}
                                    onClick={() => navigate("/crypto-screener/smart-money-picks")}
                                    icon={
                                        <img
                                            src={
                                                userData
                                                    ? loc === "/crypto-screener/smart-money-picks"
                                                        ? "/images/dollarS.svg"
                                                        : "/images/dollarU.svg"
                                                    : "/images/right-arrow.png"
                                            }
                                            width={24}
                                            height={24}
                                            alt="Icon"
                                            style={{marginBottom: "-3px"}}
                                        />
                                    }
                                />
                            </BottomNavigation>
                        </div>
                    )}
                </Box>
            )}
        </>
    );
}
