import "./portfolio.css";
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { Helmet } from 'react-helmet';
import { ButtonGroup, Popover, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import clipboardCopy from 'clipboard-copy';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist.jsx";
import MenuListHome from "../../includes/menuListHome.jsx";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Button, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import LoginPopUp from '../loginpopup';
import {ReactComponent as NotificationIcon} from "../../pages/account/profile/profile_icon/notification-icon.svg";

import LoadingButton from "@mui/lab/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import { getCryporCurrencyList } from "../../apihelper/cryptocurrencylist";
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import CircularProgress from "@mui/material/CircularProgress";
import { getFetchdropdown } from "../../apihelper/cryptocurrencylist.js";
import { getOrderBookData } from "../../apihelper/portfolio.js";
import { addToPortfolio } from "../../apihelper/portfolio.js";
import { useNavigate, Link } from "react-router-dom";
import Portfolioadd from "../addPage/portfolioadd.js";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear"; // Import ClearIcon
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ListSubheader from "@mui/material/ListSubheader";
import { Tabs, Tab } from '@mui/material';

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function OrderBook(props) {
  const [user_role, setuser_role] = useState("");
  const { portfolioId,portfolioName } = useParams();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const [snackbaropen, setSnackbaropen] = React.useState(false);

  const classes = useStyles();
  const [alignment, setAlignment] = React.useState("left");
  const [loader, setLoader] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);
  const [filter, setFilter] = useState("");
  const [loaderbutton, setLoaderButton] = useState(true);
  const [roivalue, setRoivalue] = useState("-");
  const [portfoliovalue, setPortfoliovalue] = useState("-");
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
  const [portfolioid, setportfolioid] = useState("");
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });
  const [msgAfterLoading, setMsgAfterLoading] = useState("");
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");

  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [initialPage, setInitialPage] = useState(false);
  const [localUserData,setLocalUserData]=useState(undefined)
  const [loaderForSymbolLink,setLoaderForSymbolLink]=useState(false);
  const [PortfolioRoivalue,setPortfolioRoivalue]=useState('-');
  const [currentwallet,setcurrentwallet]=useState('-');
  const [nodatafoundmsgtype,setnodatafoundmsgtype]=useState('');
  const location = useLocation(); 
  const loc = location.pathname;
  const [hasSelectedOption, setHasSelectedOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openfilter = Boolean(anchorEl);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const [currentFilter, setCurrentFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [selectedButton, setSelectedButton] = useState("PortfolioGallery");
  const [isTableView, setIsTableView] = useState(true);

  // Function to handle filter selection
  const handleFilterSelect = (filterValue) => {
    setCurrentFilter(filterValue);
    setHasSelectedOption(true);
    handleCloseeoption();
    // You can also include any additional logic here (e.g., apply the filter)
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseeoption = () => {
    setAnchorEl(null);
  };
  const handleSymbolLink=(symbolName)=>{
    //console.log(symbolName)
    setLoaderForSymbolLink(true);
    getsymbollink(symbolName).then(res=>{
      if(res.code==='200')
      window.open(res.article_link, '_blank', 'noreferrer');
      // window.location.replace(res.article_link);
      // setLoaderForSymbolLink(false);
      setLoaderForSymbolLink(false);
    })
  }

  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };

  const handleOpenDPA = (portfolioId,portfolio_name) => {
    const formattedName = portfolioName.replace(/ /g, '-');
    // e.stopPropagation();
    navigate(`/my-portfolio/${portfolioId}/${encodeURIComponent(formattedName)}/detailed-portfolio-analysis`);

  };

  const handleOpen = (idx) => {
    // setopenindex(idx);const today = new Date();
    fetchDropdownOptions();
    setSlugpopupdata({
      slugname: slugdata[idx].slug,
      slug: slugdata[idx].name,
      units: slugdata[idx].total_unit,
      pricetoday: slugdata[idx].crypto_price,
      logo: slugdata[idx].logo,
    });
    setOpen(true);
  };
  const handleGoBack = () => {
    navigate(`/my-portfolio/${portfolioId}/${portfolioName}`); // Go back to the previous page
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 400,
    })
  );
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleCreateNew = () => {
    // router.push(`/${path}`);
    navigate("/cryptocurrencylist", { replace: true });
  };

  useEffect(() => {
    setIndex(0);
    setLoader(false);
   if ((filter.length < 1) & (filter.length >= 1)) {
      setLoader(true);
      setAddMoreLoader(false);
    }
    else{
      //console.log(filter);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getOrderBookData({
        slug: filter,
        user_id: userData.user_id,
        portfolio_type_id: portfolioId,
        start_index: 0,
        limit: `${10}`,
        Token: userData.token,
        time_filter: currentFilter,
        searchfilter: searchFilter
      }).then((res) => {
        if (res.code === "200") {
          //console.log("data got successfully");
          //console.log(res);
          setSlugdata(res.data);
          //console.log(res.data)
          setuser_role(res.user_role);
          if (res.data.length < 10) {
            //console.log("change Loaderbutton");
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          setMsgAfterLoading(res.message);
          //console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  }, [filter, currentFilter, searchFilter]);

  useEffect(() => {
    //console.log(100)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    setLocalUserData(userData)
    getOrderBookData({
      slug: filter,
      portfolio_type_id: portfolioId,
      user_id: userData.user_id,
      start_index: `${index}`,
      limit: `${10}`,
      Token: userData.token,
      time_filter: currentFilter,
      searchfilter: searchFilter
    }).then((res) => {
      if (res.code === "200") {
        //console.log("data got successfully");
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setPortfolioRoivalue(res.roi_average);
        setcurrentwallet(res.current_wallet);
        setnodatafoundmsgtype(res.no_data_found_msgtype);
        setSlugdata(res.data);
        setuser_role(res.user_role);
        setLastUpdatedTiem(res.updated_on);
        setportfolioid(res.portfolio_id)
        //console.log(res.portfolio_id)
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  }, []);
  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));


    getFetchdropdown({
      user_id: userData.user_id,
      
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
       
          setuser_role(res.user_role);
       } else {
        console.log("Data fething error");
      }
    
    });
   
    
  }

  const loadMore = () => {
    // setLoader(false);
    setAddMoreLoader(true);
    setIndex(index + 10);
    //console.log(index);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getOrderBookData({
      slug: filter,
      portfolio_type_id: portfolioId,
      user_id: userData.user_id,
      start_index: index + 10,
      limit: `${10}`,
      Token: userData.token,
      time_filter: currentFilter,
      searchfilter: searchFilter
    }).then((res) => {
      if (res.code === "200") {
        //console.log("data got successfully");
        setSlugdata([...slugdata, ...res.data]);
        setuser_role(res.user_role);
        //console.log(res.data);
        if (res.data.length < 10) {
          //console.log("change Loaderbutton");
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const handleClick = () => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    addToPortfolio({
      portfolio_type_id: portfolioId,
      existing_portfolio: 'yes',
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        setLoader(false);
        getOrderBookData({
          slug: filter,
          portfolio_type_id: portfolioId,
          user_id: userData.user_id,
          start_index: `${index}`,
          limit: `${10}`,
          Token: userData.token,
          time_filter: currentFilter,
          searchfilter: searchFilter
        }).then((res) => {
          if (res.code === "200") {
            //console.log("data got successfully");
            setSlugdata(res.data);
            setuser_role(res.user_role);
            // console.log(res.data)
            if (res.data.length === 0) {
              setInitialPage(true);
            }
            if (res.data.length < 10) {
              setLoaderButton(false);
            } else {
              setLoaderButton(true);
            }
            setLoader(true);
            setAddMoreLoader(false);
          } else {
            //console.log("Data fething error");
          }
          // console.log(slugdata)
        });
        setUnit("");
        setbuySell("");
        setPrice("");
        handleDateChange("");
        //navigate("/portfolio");
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
    </div>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyText = async() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const textToCopy = `${process.env.REACT_APP_HOME_URL}portfolio/${portfolioid}`;
    await clipboardCopy(textToCopy)
   
      .then(() => {
       // console.log("Text copied:", textToCopy);

        // Set the tooltip to open for a short duration (e.g., 2 seconds)
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Error copying text:", err);
      });
  };


  const formatDateTime = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    
    // Format the date
    const [year, month, day] = datePart.split("-");
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
  
    let daySuffix = "th";
    if (day % 10 === 1 && day !== "11") {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== "12") {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== "13") {
      daySuffix = "rd";
    }
  
    const formattedDate = `${monthName} ${parseInt(day, 10)}${daySuffix}`;
  
    // Format the time
    const [hour, minute] = timePart.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12;
  
    const formattedTime = `${formattedHour}:${minute} ${ampm}`;
  
    return `${formattedDate}`;
  };
  const handleClearAll = () => {
  
    handleCloseeoption();
    setHasSelectedOption(false);
    setSelectedFilter(null);
    setCurrentFilter("");
    setSortOrder("");
   
  };
  // useEffect to monitor sortOrder changes and trigger actions
   useEffect(() => {
    if (sortOrder) {
      setHasSelectedOption(true);
      handleCloseeoption();
    }
  }, [sortOrder]);
   // Filter slugdata based on the searchFilter value (case-insensitive)
   
  // Sort slugdata using a simple comparison instead of localeCompare
  const sortedSlugData = sortOrder
    ? slugdata.sort((a, b) => {
        if (sortOrder === 'asc') {
          
          return a.slug.toLowerCase() > b.slug.toLowerCase() ? 1 : -1; // A to Z sorting
        } else {
    
          return a.slug.toLowerCase() < b.slug.toLowerCase() ? 1 : -1; // Z to A sorting
        }
        
      })
    : slugdata; // Default order when no sort order is applied
    const currentUrl = window.location.pathname; // Get the current URL path
    let url;
    if (currentUrl.includes('/my-portfolio/')) {
      url = `/my-portfolio/${portfolioId}/${portfolioName}/detailed-portfolio-analysis`;
    } else if (currentUrl.includes('/portfolio/')) {
      url = `/portfolio/${portfolioName}/detailed-portfolio-analysis`;
    }


    const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);

  return (
    
    <>
    {mediaQueryVar === true ? (
    <Box
    sx={{
      flexDirection: mediaQueryVar === false ? "column" : "",
   
      display: 'flex',  height: '100vh' 
      
    }}
    className="maindiv"
  >
    <Helmet>
        <title>Order Book</title>
</Helmet>
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={window.constants.asset_path  +'/images/logo_with_bg.png'}
              width={220}
              height={50}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />
          </Toolbar>
        </AppBar>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "0px 10px 10px 0px",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "0px 10px 10px 0px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: "2vw",
          backgroundColor: "#EDF1F6",
        }}
      >
       
            <div className="windowheader">
              <Toolbar />
            </div>
            <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"15px",
              marginBottom:"10px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
            </Button>
            <Box sx={{ flexGrow: 1, marginBottom: "15px" }} >
            

                
<Box sx={{ flexGrow: 1, marginBottom: "15px" }}>
  <Grid
    container
    spacing={2}
    style={{ display: "flex", justifyContent: "flex-start" }} // Set justifyContent to flex-start
  >
    <Grid item className="CryptocurrencyListHeader">
      <Box sx={{ marginTop: '10px' }}>
        <Typography noWrap style={{
          fontFamily: "Satoshi, sans-serif",
          fontSize: "13px",
          fontStyle: "normal",
        }}>
          <div style={{ padding: "0px 10px 0px 10px", color: "black", border: "none", fontWeight: "bold", fontSize: mediaQueryVar === false ? "15px" : "24px", marginLeft: "10px" }}>
          <Box
      sx={{
        backgroundColor: "#1877F2", // Background color for the container
        paddingTop: {xs:'10px', sm:'4px'},// Padding inside the container
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", // Border radius for the container
        display: 'flex', // Make sure the box is flexible
        justifyContent: 'flex-start', // Center the content
        alignItems: 'center', // Center the content
        width: "330px",
        height:{xs:'44px', sm:'48px'},
        border:'none',
        marginLeft:"0px",
        marginTop:"0px"
      }}
    >
                  
                  <Tabs
  value={selectedButton}
  onChange={(event, newValue) => handleButtonClick(newValue)}
  aria-label="basic tabs example"
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", // Adjust border radius for more rounded corners
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', // Make sure the tabs are displayed in a row
    overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
  }}
>
  <Tab
    label="Order Book"
    value="PortfolioGallery"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'17px'},
      fontStyle: "normal",
      fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
      
      backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="Portfolio Analysis"
    value="CoinPrices"
    component={Link}
        to={url}
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
      
      backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>

</Box>
          </div>
        </Typography>
      </Box>
    </Grid>
    <div>
    <TextField
        style={{
          width: "auto",
          borderRadius: "46px",
          marginTop: "27px",
          fontFamily: "Satoshi, sans-serif",
          marginRight: "25px",
          border: "1px #b6b6b8 solid",
          backgroundColor: "white",
          paddingTop: "0px",
          paddingBottom: "2px",
          paddingLeft: "12px",
          paddingRight: "30px",
          marginBottom: "0px",
          fontSize: "14px",
          fontWeight: "400",
          marginLeft: "1%"
        }}
        size="small"
        placeholder="Search Crypto"
        onChange={(e) => setSearchFilter(e.target.value)}
        value={searchFilter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                width={13}
                height={13}
                src={window.constants.asset_path + '/images/search.svg'}
                alt="search Icon"
              />
            </InputAdornment>
          ),
          style: {
            border: "none",
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
      />
      </div>
    {/* Spacer to push Filters button to the right */}
    <Grid item xs />

    {/* Right-aligned Filters Button */}
    <Grid item>
    <div style={{display:"flex", alignItems:"center"}}>
      <Button
        startIcon={<img src={window.constants.asset_path + "/images/filter_main.svg"} />}
        endIcon={<img src={window.constants.asset_path + "/images/arrowdown.svg"} />}
        variant="outlined"
        sx={{
          "&:hover": {
            background: "rgba(24, 119, 242, 0.04)", // Set the hover color
            border: "1px solid #1877F2",
          },
          background: "rgba(24, 119, 242, 0.04)",
          width: "auto",
          height: "36.86px",
          color: "#1877F2",
          padding: "6px 12px",
          textTransform: "none",
          borderRadius: "6px",
          border: "1px #1877F2 solid",
          marginRight: "30px", // Optional: Adjust margin if needed
          marginBottom: "18px",
          marginTop:"10px"
        }}
        onClick={handlePopoverOpen}
      >
        {hasSelectedOption && (
          <IconButton size="small">
            <CheckCircleOutlineIcon style={{ color: "green" }} />
          </IconButton>
        )}
        <span style={{
          fontFamily: "Satoshi, sans-serif",
          fontSize: "16px",
          fontWeight: "400",
          color: "#1877F2" // Fixed the property name from fontColor to color
        }}>Filters</span>
      </Button>
      <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>
                                    </div>
      
      {/* Popover component */}
      <Popover
        open={openfilter}
        anchorEl={anchorEl}
        onClose={handleCloseeoption}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => handleFilterSelect("last_day")} selected={selectedFilter === "last_day"}>
          1 Day
        </MenuItem>
        <MenuItem onClick={() => handleFilterSelect("last_week")} selected={selectedFilter === "last_week"}>
          1 Week
        </MenuItem>
        <MenuItem onClick={() => handleFilterSelect("last_month")} selected={selectedFilter === "last_month"}>
          1 Month
        </MenuItem>
        
        <Divider />
      
        <ListSubheader>Sort Crypto</ListSubheader>
              <RadioGroup
                aria-label="verification-status"
                name="verification-status"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <MenuItem>
                  <FormControlLabel
                    value="asc"
                    control={<Radio size="small" />}
                    label={
                      <Typography sx={{ fontWeight: "500" }}>
                        A to Z
                      </Typography>
                    }
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    value="desc"
                    control={<Radio size="small" />}
                    label={
                      <Typography sx={{ fontWeight: "500" }}>
                        Z to A
                      </Typography>
                    }
                  />
                </MenuItem>
              </RadioGroup>
        <Box sx={{ display: "flex", padding: "8px" }}>
          <Button
            size="small"
            sx={{ textTransform: "capitalize", marginLeft: "10px" }}
            onClick={handleClearAll}
            startIcon={<ClearIcon />}
          >
            Clear All
          </Button>
        </Box>
      </Popover>
    </Grid>
    
    {mediaQueryVar === true && (
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        width: mediaQueryVar === false ? "100%" : "",
        marginLeft: "18px",
        fontSize: "14px",
      }}>
        {/* Additional content can go here */}
      </div>
    )}
  </Grid>
  
</Box>
            </Box>   
             
{initialPage &&   <Box
        component="main"
        sx={{
         
          flexGrow: 1,
          p: 3,
         
        }}
      >

<Grid container spacing={2}  sx={{marginTop:'0px',marginBottom:"9px", marginLeft: mediaQueryVar === false ? "-5px" : "",}}>

</Grid>
        
      </Box>}
        {!initialPage && (
          <>
            <div style={{ border: "1px solid white",  borderRadius: "8px", padding:"32px", margin:"0px 25px 10px 10px", boxShadow: "0 2px 4px rgba(0,0,0,0)", backgroundColor: "white" }}>
            
              <TableContainer style={{ overflowX: "hidden", border: "1px solid #DADEDF", borderRadius: "5px", padding:0 }}>
                <Table
                  className="non_responsiveTable" aria-label="customized table" 
                  style={{ borderCollapse: "collapse" }}
                >
                  <TableHead>
                  <TableRow>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ borderRadius: "8px 0px 0px 8px", border: 0, padding: 0, width:"23%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Crypto
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"12%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Units
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"20%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Date
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"13%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                            Price
                          </button>
                        </StyledTableCell>
                        
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"17%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                           Buy/Sell
                          </button>
                        </StyledTableCell>
                        <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"17%" }}>
                          <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
                           Change in Price
                          </button>
                        </StyledTableCell>
                        
                        
                                            </TableRow>
                  </TableHead>
                  {loader === false && (
                    <TableBody>
                      {rows.map((idx) => (
                        <StyledTableRow key={idx} >
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                              sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell >
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                  {loader && (
                    <TableBody>
                    {sortedSlugData.map((obj, idx) => (
                      <StyledTableRow key={idx} style={{ backgroundColor: idx % 2 === 0 ? "white" : "#F0F0F0"}}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                              <img width={39.77} height={38} style={{borderRadius: "19px", border:"2px solid black"}} alt="" src={obj.logo} />
                            </div>
                            <div className="btcParent">
                              {obj["article_link"] && (
                                <a href={obj.article_link}>
                                  <div>{obj.slug}</div>
                                </a>
                              )}
                              {!obj["article_link"] && <div className="article-link">{obj.slug}</div>}
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.total_unit}
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          <div>
                          {formatDateTime(obj.last_updated)}
  </div>
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.acquisitionPrice}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                         <div>
                             <div style={{ display: "flex", flexDirection: "column" }}>
                                {obj.type === 'sell' ? (
                                   <button disabled style={{ alignItems: 'center', fontWeight:"bold", padding:"5px",borderRadius: "5px", backgroundColor: '#FFE3E3', color:'#FF4141', border: '2px solid #FF4141', width:"50%" }}>Sell</button>
                                ) : (
                               <button disabled style={{ alignItems: 'center',fontWeight:"bold",padding:"5px", borderRadius: "5px", backgroundColor: '#DFFFF0', color:'#199F5F', border: '2px solid #199F5F', width:"50%" }}>Buy</button>
                                )}
                             </div>
                         </div>

                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.change_in_price}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  
                  )}
                </Table>
                {slugdata.length === 0 && loader === true && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </TableContainer>
              {loader && loaderbutton && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      marginBottom:"60px",
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
            </div>
            </>
        )
      }
      
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
      </Box>
    </Box>
    ) : (
      <Box
      sx={{
        display: "flex",
        height: '100vh' 
      }}
      className="maindiv"
    >  
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      
      <Box
        
        component="main"
        sx={{
      flexGrow: 1,
      p: 2,
      backgroundColor: "white",
      paddingBottom: "2vw",
      minHeight: "100vh",
      overflowY:"auto"
        }}
      >

        <div style={{display:'flex', alignItems:"center",justifyContent:"flex-end", marginBottom:"0px"}}>
                                 <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                                <NotificationIcon className="notification-icon fa-2x" />
                                                <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                                      {unreadAlerts}
                                                </span>
                                            </div>
                                <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="end"
                                        onClick={handleDrawerToggle}
                                        sx={{
                                            display: {sm: "none"},
                                        }}
                                    >
                                        <img
                                            src={window.constants.asset_path + "/images/menubar.svg"}
                                            alt="Menu Icon"
                                            style={{width: "24px", height: "24px", marginRight: "10px", marginTop: "0px"}}
                                        />
                                    </IconButton>
                                    </div>
     
           
  <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "-20px", 
  marginBottom: "10px" ,
  marginLeft:"5px",
  
}}>
   <Box sx={{ flexGrow: 1, marginBottom: "15px", }}>
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "space-between" 
          
        }}
      >
        <Grid item className="CryptocurrencyListHeader">
          <Box sx={{ marginTop: "10px" }}>
            <Typography
              noWrap
              style={{
                fontFamily: "Satoshi, sans-serif",
                fontSize: "13px",
                fontStyle: "normal",
              }}
            >
              
              </Typography>
              <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Ensures items are spaced apart
    alignItems: "center", // Vertically aligns items in the center
    marginBottom: "5px",
    marginTop: "20px",
    width: "100%", // Make sure the Box takes the full width of the parent
  }}
>
<Box
    sx={{
      backgroundColor: "#1877F2", // Background color for the container
      padding: "3.105px", // Padding inside the container
      borderRadius: "38.042px", // Border radius for the container
      display: 'flex', // Ensure that the box is flexible
      alignItems: 'center', // Center the content vertically
      width: mediaQueryVar2?"250px":"280px", // Full width on extra small screens
      height: "36.63px",
      border: 'none',
      marginLeft: "0px",
      marginTop: "3%",
      marginBottom:"2%"
    }}
  >
    <Tabs
      value={selectedButton}
      onChange={(event, newValue) => handleButtonClick(newValue)}
      aria-label="basic tabs example"
      sx={{
        flexGrow: 1, // Make the tabs take up remaining space
        "& .MuiTabs-indicator": {
          display: "none",
        },
        display: 'flex', // Ensure that the tabs are displayed in a row
        overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
      }}
    >
      <Tab
        label="Order Book"
        value="PortfolioGallery"
        
        sx={{
          whiteSpace: "nowrap",
          borderRadius: "20.962px",
          minHeight: "31.5px",
          height: "31.5px",
          fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
          marginLeft: "1px",
          paddingLeft: "-15px",
          marginTop: "8px",
          border: "1px solid black",
          marginBottom: "-5px",
          fontSize: mediaQueryVar2?"13px":"15px",
          marginRight: "-15px",
          backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
          color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
          textTransform: "none",
          border: "none"
        }}
      />
      <Tab
        label="Portfolio Analysis"
        value="CoinPrices"
        component={Link}
        to={url}
        sx={{
          whiteSpace: "nowrap",
          borderRadius: "20.962px",
          minHeight: "31px",
          height: "31px",
          marginLeft: "5px",
          marginTop: "8px",
          border: "1px solid black",
          fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
          marginRight: "0px",
          fontSize: mediaQueryVar2?"13px":"15px",
          paddingRight: "-8px",
          textTransform: "none",
          backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
          color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
          border: 'none', // Remove border for tabs
        }}
      />
    </Tabs>
  </Box>

  <Box
    sx={{
      display: "flex",
      justifyContent: "flex-end", // Align content to the right
      
    }}
  >
    <Button onClick={handlePopoverOpen}>
      <div
        style={{
          border: "2px solid #1877F2",
          borderRadius: "50%",
          padding: "8px 9px",
          marginRight: mediaQueryVar2? "-40px":"-130px", // Push this Box to the right
        }}
      >
        <img
          width="20px"
          src={window.constants.asset_path + "/images/filter.svg"}
          alt="Filter icon"
        />
      </div>
    </Button>

    <Popover
      open={openfilter}
      anchorEl={anchorEl}
      onClose={handleCloseeoption}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuItem
        onClick={() => handleFilterSelect("last_day")}
        selected={selectedFilter === "last_day"}
      >
        1 Day
      </MenuItem>
      <MenuItem
        onClick={() => handleFilterSelect("last_week")}
        selected={selectedFilter === "last_week"}
      >
        1 Week
      </MenuItem>
      <MenuItem
        onClick={() => handleFilterSelect("last_month")}
        selected={selectedFilter === "last_month"}
      >
        1 Month
      </MenuItem>

      <Divider />
      <ListSubheader>Sort Crypto</ListSubheader>
      <RadioGroup
        aria-label="verification-status"
        name="verification-status"
        value={sortOrder}
        onChange={(e) => setSortOrder(e.target.value)}
      >
        <MenuItem>
          <FormControlLabel
            value="asc"
            control={<Radio size="small" />}
            label={
              <Typography sx={{ fontWeight: "500" }}>A to Z</Typography>
            }
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            value="desc"
            control={<Radio size="small" />}
            label={
              <Typography sx={{ fontWeight: "500" }}>Z to A</Typography>
            }
          />
        </MenuItem>
      </RadioGroup>
      <Box sx={{ display: "flex", padding: "8px" }}>
        <Button
          size="small"
          sx={{ textTransform: "capitalize", marginLeft: "10px" }}
          onClick={handleClearAll}
          startIcon={<ClearIcon />}
        >
          Clear All
        </Button>
      </Box>
    </Popover>
  </Box>
</Box>
</Box>
        </Grid>
        {mediaQueryVar === true && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              width: mediaQueryVar === false ? "100%" : "",
              marginLeft: "10px",
              fontSize: "14px",
            }}
          ></div>
        )}
      </Grid>
    </Box>

</div>
<div>
    <TextField
        style={{
          width: "95%",
          borderRadius: "46px",
          marginTop: "-25px",
          fontFamily: "Satoshi, sans-serif",
          marginRight: "25px",
          border: "1px #b6b6b8 solid",
          backgroundColor: "white",
          paddingTop: "0px",
          paddingBottom: "2px",
          paddingLeft: "12px",
          paddingRight: "30px",
          marginBottom: "5%",
          fontSize: "14px",
          fontWeight: "400",
          marginLeft: "8px"
        }}
        size="small"
        placeholder="Search Crypto"
        onChange={(e) => setSearchFilter(e.target.value)}
        value={searchFilter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                width={13}
                height={13}
                src={window.constants.asset_path + '/images/search.svg'}
                alt="search Icon"
              />
            </InputAdornment>
          ),
          style: {
            border: "none",
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
      />
      </div>
    {initialPage && (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          overflowY:"auto"
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0px",
            marginBottom: "9px",
            marginLeft: mediaQueryVar === false ? "-5px" : "",
          }}
        ></Grid>
        
      </Box>
    )}
    {!initialPage && (
      <>
        {loader === false && (
          <Box sx={{ padding: "16px", backgroundColor: "#FFF", borderRadius: "8px", margin: "16px 0" }}>
            {rows.map((idx) => (
              <Box
                key={idx}
                sx={{
                  padding: "16px",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  marginBottom: "16px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                  <Skeleton
                    sx={{ marginRight: "16px", borderRadius: "8px" }}
                    variant="rectangular"
                    width={40}
                    height={40}
                  />
                  <Skeleton variant="text" width={200} height={30} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width="30%" height={30} />
                  <Skeleton variant="text" width="30%" height={30} />
                  <Skeleton variant="text" width="30%" height={30} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {loader && (
          <Box sx={{
            marginBottom:"70px",
            overflowY:"auto",
            marginTop:"-20px",
            "&::-webkit-scrollbar": {
      display: "none",
    },
    // Hides scrollbar for Firefox
    scrollbarWidth: "none", }}>
            {sortedSlugData.map((obj, idx) => (
              <Box
                key={idx}
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  margin: "15px 10px",
                  border: "1px solid #DADEDF",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
                  <img
                    src={obj.logo}
                    alt={obj.slug}
                    width={40}
                    height={40}
                    style={{ borderRadius: "19px", marginRight: "16px", border:"2px solid #000", width:"40px", height:"40px", margin : "8px" }}
                  />
                  <Typography variant="h6" style={{fontSize:"18px", fontWeight:"500"}}>{obj.slug}</Typography>
                </Box>
                <Box sx={{background:"#EBE9EB", borderRadius:"6px", padding: "2px 10px 2px 10px"}}>
                  <div style={{ display: "flex", justifyContent: "space-between", margin:"3px" }}>
                    <span style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}} variant="subtitle1">Units:</span>
                    <span style={{textAlign: "right",fontWeight:"500", fontSize:"14px"}} variant="body1">{obj.total_unit}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", backgroundColor:"white", borderRadius:"4px", paddingLeft:"3px", paddingRight:"3px"}}>
                    <span variant="subtitle1" style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}}>Date:</span>
                    <span variant="body1" style={{textAlign: "right", fontWeight:"500", fontSize:"14px"}}>
                    {formatDateTime(obj.last_updated)}
                    </span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", margin:"4px"}}>
                    <span variant="subtitle1" style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}}>Aquisition price:</span>
                    <span variant="body1" style={{textAlign: "right", fontWeight:"500", fontSize:"14px"}}>{obj.acquisitionPrice}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", backgroundColor:"white", borderRadius:"4px", margin:"5px 1px 5px 1px", padding:"5px 4px" }}>
                    <span variant="subtitle1" style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}}>Buy/Sell:</span>
                    {obj.type === 'sell' ? (
                                   <button disabled style={{ alignItems: 'center', fontWeight:"bold", borderRadius: "5px", backgroundColor: '#FFE3E3', color:'#FF4141', border: '1px solid #FF4141', width:"30%", height:"18%", padding:"2px" }}>Sell</button>
                                ) : (
                               <button disabled style={{ alignItems: 'center',fontWeight:"bold", borderRadius: "5px", backgroundColor: '#DFFFF0', color:'#199F5F', border: '1px solid #199F5F', width:"30%", height:"18%", padding:"2px"}}>Buy</button>
                                )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", margin:"4px"}}>
                    <span variant="subtitle1" style={{textAlign: "left", fontSize:"13px", color:"#76787A", fontWeight:"500"}}>Change in Price:</span>
                    <span variant="body1" style={{textAlign: "right", fontWeight:"500", fontSize:"14px"}}>{obj.change_in_price}</span>
                  </div>
                </Box>
              </Box>
            ))}
            {slugdata.length === 0 && loader === true && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h4>No Data Found</h4>
                  </div>
                )}
            {loader && loaderbutton && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "40vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
          </Box>
        )}
      </>
    )}
  </Box>
  {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginLeft:"-10px"
          }}
        >
          <BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
        </div>
      )}

{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
</Box>

    )}
  </>
  );
}

export default OrderBook;