import React, { useEffect, useState } from "react";
import {
    useAppKitAccount,
    useAppKitNetwork,
    useAppKitProvider,
    useDisconnect,
} from "@reown/appkit/react";
import {
    SystemProgram,
    PublicKey,
    Transaction,
    LAMPORTS_PER_SOL,
    Connection,
    clusterApiUrl,
} from "@solana/web3.js";
import { saveCryptoPresalePayment } from "../presale_form/api";
import { recipientAddress } from "../../../common/constant/api_key";
import ProcessingAlert from "../../../common/alert/ProcessingAlert";
import SuccessPaymentAlert from "../../../common/alert/SuccessPaymentAlert";
import { solana } from "@reown/appkit/networks";
import { useNetwork } from "../../../ReownAppKitProvider.tsx";

const SOLConnectButton = ({ paymentMethod, select_price }) => {
    const { isConnected, address } = useAppKitAccount();
    const { disconnect } = useDisconnect();
    const { walletProvider } = useAppKitProvider("solana", {
        autoSwitchNetwork: false,
    });
    const { switchNetwork, chainId } = useAppKitNetwork();
    const isWrongNetwork = chainId !== solana.id;

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { selectedNetwork, setSelectedNetwork } = useNetwork();
    useEffect(() => {
        setSelectedNetwork("solana");
    }, [setSelectedNetwork]);

    // Connection to Solana cluster
       // for testnet
    // const connection = new Connection(clusterApiUrl("devnet"), "confirmed");

    // Testing quicknode api endpoint
    const connection = new Connection("https://alpha-crimson-diagram.solana-mainnet.quiknode.pro/12683d2983a09482058b04c03b66a20eff0732b6/", "confirmed");
    // const connection = new Connection(
    //     "https://solana-mainnet.g.alchemy.com/v2/IesGw7t2mCeN_nIOQFp4avF8XCgZDq5P",
    //     "confirmed"
    // );

    // for mainnet (not working)
    // const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");

    useEffect(() => {
        const switchToSolana = async () => {
            if (isWrongNetwork && switchNetwork) {
                alert("Switching to Solana network...");
                await switchNetwork(solana);
            }
        };
        switchToSolana();
    }, [isWrongNetwork, address, switchNetwork]);

    useEffect(() => {
        if (isConnected) {
            console.log("Connected to wallet:", address);
            // Check if the connected address is the one you expect
            if (address === "3zsZ4HxiUq3vkC8RXgKznKMq85PXDD4YqKX6J7fjwwbD") {
                console.log("You are connected to the unexpected wallet.");
            } else {
                console.warn("Connected to an expected wallet:", address);
            }
        } else {
            console.log("Wallet is not connected.");
        }
    }, [isConnected, address]);

    const initiateTransaction = async (provider, connection, address) => {
        const wallet = new PublicKey(address);
        const latestBlockhash = await connection.getLatestBlockhash();

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: wallet,
                toPubkey: new PublicKey(recipientAddress),
                lamports: LAMPORTS_PER_SOL * 0.0001, // Adjust this to the correct amount
            })
        );
        transaction.feePayer = wallet;
        transaction.recentBlockhash = latestBlockhash.blockhash;

        try {
        console.log("Checking wallet provider capabilities:", provider);

        if (typeof provider.signTransaction === "function") {
            const signedTransaction = await provider.signTransaction(transaction);
            const signature = await connection.sendRawTransaction(signedTransaction.serialize());
            await connection.confirmTransaction(signature, { commitment: "confirmed" });
            console.log("Transaction successful:", signature);
            return signature;
        // } else if (typeof provider.signAndSendTransaction === "function") {
        //     const signature = await provider.signAndSendTransaction(transaction);
        //     console.log("Transaction successful:", signature);
        //     return signature;
        // } else if (typeof provider.sendTransaction === "function") {
        //     const signature = await provider.sendTransaction(transaction, connection);
        //     await connection.confirmTransaction(signature, { commitment: "confirmed" });
        //     console.log("Transaction successful:", signature);
            return "signature";
        } else {
            throw new Error("Wallet provider does not support signing transactions.");
        }
    } catch (error) {
        console.error("Transaction failed:", error);
        alert("Failed to send transaction. Please check your network connection.");
    }
    };


    const handleSendSol = async () => {
        if (!select_price) {
            alert("Please select an amount");
            return;
        }
        try {
            setLoading(true);
            const balance = await connection.getBalance(new PublicKey(address));
            const balanceInSol = balance / LAMPORTS_PER_SOL; // Convert balance from lamports to SOL
            console.log("User  Balance:", balanceInSol, "SOL");

            // Calculate the amount to send based on the selected price
            const amountToSend = select_price; // Adjust this based on your logic

            // if (balanceInSol < amountToSend) {
            //     alert("Insufficient balance. Please add more SOL to your wallet.");
            //     return;
            // }

            const transactionResponse = await initiateTransaction(walletProvider, connection, address);
            console.log("Transaction response:", transactionResponse);

            // Save the transaction details
            await saveCryptoPresalePayment(
                recipientAddress,
                address,
                transactionResponse,
                select_price,
                "sol"
            );

            setIsSuccess(true);
        } catch (error) {
            alert(`Transaction failed: ${error.message}`);
            console.error("Error sending SOL:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDisconnect = async () => {
        setLoading(true);
        try {
            await disconnect();
            setIsSuccess(false);
        } catch (error) {
            console.error("Disconnection failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            handleDisconnect();
        }
    }, [paymentMethod]);

    return loading ? (
        <ProcessingAlert />
    ) : (
        <>
            <div style={{ textAlign: "center" }}>
                <appkit-button />
                {isConnected && <button onClick={handleSendSol}>Pay with {paymentMethod.toUpperCase()}</button>}
            </div>
            <SuccessPaymentAlert open={isSuccess} onClose={() => setIsSuccess(false)} />
        </>
    );
};

export default SOLConnectButton;