import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Snackbar, Alert, Autocomplete } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { completeGoogleLogin } from "../apihelper/login";
import Box from "@mui/material/Box";
import {ReactComponent as NotificationIcon} from "../pages/account/profile/profile_icon/notification-icon.svg";

import Drawer from '@mui/material/Drawer';
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import './CryptoPage.css'; // Assuming you have a CSS file for styling
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import { addDays, format } from 'date-fns'
import IconButton from "@mui/material/IconButton";
import { Helmet } from 'react-helmet';
import LoginPopUp from './loginpopup';
import { InputAdornment } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Footer from "./footer.js";

const drawerWidth = 292;

const CryptoForecastForm = (props) => {
  const location = useLocation();
  const { cryptoValue } = location.state || {}; // Safely access the crypto value

  console.log('Crypto Value:', cryptoValue);
  const { window1 } = props;
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [name, setName] = useState(cryptoValue);
  const [targetPrice, setTargetPrice] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    // Set the default value to the current month and year in "YYYY-MM" format
    const currentDate = new Date();
    const defaultDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}`;
    setEndDate(defaultDate);
  }, []);
  const [sourceUrl, setSourceUrl] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const container = window1 !== undefined ? () => window1().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cryptoOptions, setCryptoOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  // Calculate the minimum date that can be selected (two days after today)
  const minDate = format(addDays(new Date(), 2), 'yyyy-MM-dd');
  const [mediaQueryVar1, setMediaQueryVar] = useState(window.innerWidth < 900);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const loc = location.pathname;
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  
const drawer = (
  <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
  </div>
);
const [solanaWalletAddress, setSolanaWalletAddress] = useState('');
const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
};
 const [currentPrice, setCurrentPrice] = useState(null);
useEffect(() => {
  const fetchCryptoOptions = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/getCryptoSlug'); // Replace with your API endpoint
      const data = await response.json();
      if (data.symbols) {
        setCryptoOptions(data.symbols.map((item) => item.symbol));
      }
    } catch (error) {
      console.error("Error fetching crypto symbols", error);
    }
  };

  fetchCryptoOptions();
}, []);

const handleGoBack = () => {
  navigate(-1); // Go back to the previous page
};


const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the max-width based on your needs



const handleSubmit = async (e) => {
  e.preventDefault();

  if (!name || !targetPrice || !endDate || !solanaWalletAddress) {
      setError("All fields are required.");
      return;
  }

  const userId = JSON.parse(localStorage.getItem("cw_portfolio_user"))?.user_id;
  const emailId = userId ? JSON.parse(localStorage.getItem("cw_portfolio_user"))?.email_id : '';
  const user_name = userId ? JSON.parse(localStorage.getItem("cw_portfolio_user"))?.full_name : '';
 
  try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/enterFormData', {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_name,name, targetPrice, endDate, outlook, solanaWalletAddress, emailId }),
      });
      const data = await response.json();
      if (data.code === '200') {
          setSuccessMessage("Prediction Added");
          setName("");
          setTargetPrice("");
          setEndDate("");
          setSourceUrl("");
          // Use setTimeout to delay the navigation until after the state update
          setTimeout(() => {
              navigate("/crypto-screener");
          }, 10000);
      } else {
          setError("Failed to add prediction.");
      }
  } catch (err) {
      setError("An error occurred. Please try again.");
  }
};


  const handleInputChange = (event, newValue) => {
    setName(newValue);
    if (newValue) {
      setFilteredOptions(cryptoOptions.filter(option => option.toLowerCase().includes(newValue.toLowerCase())));
    } else {
      setFilteredOptions([]);
    }
    if (newValue) fetchCryptoPrice(newValue);
  };

  useEffect(() => {
    const handleResize = () => setMediaQueryVar(window.innerWidth < 900);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let timer;
    if (error || successMessage) {
      timer = setTimeout(() => {
        setError('');
        setSuccessMessage('');
      }, 6000); // 6000 milliseconds = 6 seconds

      const handleInteraction = () => {
        setError('');
        setSuccessMessage('');
      };

      window.addEventListener('click', handleInteraction);
      window.addEventListener('touchstart', handleInteraction);

      return () => {
        clearTimeout(timer);
        window.removeEventListener('click', handleInteraction);
        window.removeEventListener('touchstart', handleInteraction);
      };
    }
  }, [error, successMessage]);

  const [outlook, setOutlook] = useState("");
  const outlookOptions = ["Buy", "Sell", "Hold"];
  const handleOutlookChange = (_, newValue) => {
    setOutlook(newValue);
  };

  const fetchCryptoPrice = async (cryptoName) => {
    console.log("Crypto Name: ", cryptoName);

    if (!cryptoName) {
        console.error("Crypto name is required");
        setError("Crypto name is required.");
        return;
    }

    try {
        const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/getcurrentcryptoprice", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                slug: cryptoName, 
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.code !== "200") {
            console.error("Error from API:", data.message);
           
        } else {
            console.log(data);
            setCurrentPrice(data.current_price);
            setTargetPrice(data.current_price); // Set target price as current price
            setError(""); // Clear any previous errors
        }
    } catch (error) {
        console.error("Error fetching crypto price:", error);
       
    }
};

const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);



  return (
    <>
    <div className="layout" 
    style=
    {{ 
      backgroundColor: '#fff', 
      minHeight: '110vh', 
      padding: '20px',
      
       }}>

<Helmet>
        <title>Predict Now</title>
</Helmet>
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>


    <div className="content">
    <div style={{display:"flex", justifyContent:"space-between"}}>
    <div onClick={handleGoBack}
            style={{
              width:"85px",
              height:"38px",
              marginBottom:"0px",
              marginTop:"0px",
              display:"flex",
              alignItems: "center",
              
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{color:"black",fontSize: "20px",marginLeft: "5px",fontFamily: "'Satoshi', sans-serif",}}>Predict</span>
            
            
      </div>
 <div style={{display:'flex'}}>
      <div className="notification-section position-relative" onClick={() => navigate("/alerts")} style={{marginLeft:'15px'}}>
                  <NotificationIcon className="notification-icon fa-2x" />
                         <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                 {unreadAlerts}
                           </span>
                     </div>
      <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "26px", 
                    height: "26px",
                    marginRight: "0px",
                }} 
            />
      </IconButton>
      </div>
      </div>
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      <div style={{  }}>
  <Button disabled variant="contained" color="primary" style={{ color: "black",fontFamily: "'Satoshi', sans-serif",
    background: '#D1D1D1', textTransform: "none", width: 'auto', height: "10%", marginBottom: '20px', fontWeight: '500', fontSize: '15.575px', borderRadius: "25px" 
    ,marginTop: mediaQueryVar===true ? "" : "3%", marginBottom: mediaQueryVar===true ? "50px" : "",lineHeight:"1.3"
    }}>
     Predict the Price of any Crypto and receive $5 worth of $WISD tokens.
  </Button>
  <div style={{width: mediaQueryVar ? "50%" : "100%"}}>
  <form onSubmit={handleSubmit} style={{ marginTop: mediaQueryVar===true ? "-30px" : "12%" }}>
  <Typography style={{
    color: "#425466",
    fontFamily: "'Satoshi', sans-serif",
    fontSize: mediaQueryVar===true ? "16px" : "14px",
  }}>Select the crypto you want to predict*</Typography>
  <Autocomplete
    freeSolo
    options={filteredOptions}
    value={name}
    onInputChange={handleInputChange}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        required
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px', // Increase the border radius as needed
            backgroundColor: '#EDF2F7', // Ensuring consistent background
            width:mediaQueryVar == true ?'100%' : '100%',
            '& input': {
              color: '#000000', // Explicitly setting input text color to black
            },
            '& fieldset': {
              borderColor: '#EDF2F7',
            },
            '&:hover fieldset': {
              borderColor: '#EDF2F7',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#EDF2F7',
            },
          },
        }}
      />
    )}
  />
  <Typography style={{
    color: "#425466",
    fontFamily: "'Satoshi', sans-serif",
    fontSize: mediaQueryVar===true ? "16px" : "14px",
    marginTop: "15px",
  }}>Predict the Price Here*</Typography>
  <TextField
    fullWidth
    type="number"
    step="0.01"
    value={targetPrice || ''} // Ensure no uncontrolled component warnings
    onChange={(e) => setTargetPrice(e.target.value)} // Allow user input updates
    required
    sx={{
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px', // Customize as needed
            backgroundColor: '#EDF2F7',
            width:mediaQueryVar == true ?'100%' : '100%',
            '& input': {
                color: '#000000',
            },
            '& fieldset': {
                borderColor: '#EDF2F7',
            },
            '&:hover fieldset': {
                borderColor: '#EDF2F7',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#EDF2F7',
            },
        },
    }}
/>


<Typography
  style={{
    color: "#425466",
    fontFamily: "'Satoshi', sans-serif",
    fontSize: mediaQueryVar === true ? "16px" : "14px",
    marginTop: "15px",
  }}
>
  Choose the Month and Year for which you are predicting*
</Typography>
<TextField
  variant="outlined"
  fullWidth
  margin="normal"
  type="date"
  InputLabelProps={{ shrink: true }}
  value={endDate}
  onChange={(e) => setEndDate(e.target.value)}
  required
  inputProps={{ min: minDate }}
  sx={{
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: '#EDF2F7',
      width: '100%',
      '& input': {
        color: '#000000',
      },
      '& fieldset': {
        borderColor: '#EDF2F7',
      },
      '&:hover fieldset': {
        borderColor: '#EDF2F7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#EDF2F7',
      },
    },
    
  }}
  
/>



  <Typography
        style={{
          color: "#425466",
          fontFamily: "'Satoshi', sans-serif",
          fontSize: mediaQueryVar===true ? "16px" : "14px",
          marginTop: "15px",
        }}
      >
        Choose your outlook for the crypto*
      </Typography>
      <Autocomplete
        options={outlookOptions}
        value={outlook}
        onChange={handleOutlookChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                backgroundColor: "#EDF2F7",
                width:mediaQueryVar == true ?'100%' : '100%',
                "& input": {
                  color: "#000000",
                },
                "& fieldset": {
                  borderColor: "#EDF2F7",
                },
                "&:hover fieldset": {
                  borderColor: "#EDF2F7",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        )}
      />

<Typography
  style={{
    color: "#425466",
    fontFamily: "'Satoshi', sans-serif",
    fontSize: mediaQueryVar===true ? "16px" : "14px",
    marginTop: "15px",
  }}
>
Enter Your Solana Address For the $5 worth $WISD Airdrop*
</Typography>
<TextField
  fullWidth
  type="text"  // Use text type for wallet addresses
  value={solanaWalletAddress || ''} // Ensure no uncontrolled component warnings
  onChange={(e) => setSolanaWalletAddress(e.target.value)} // Allow user input updates
  required
  sx={{
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px', // Customize as needed
      backgroundColor: '#EDF2F7',
      width:mediaQueryVar == true ?'100%' : '100%',
      '& input': {
        color: '#000000',
      },
      '& fieldset': {
        borderColor: '#EDF2F7',
      },
      '&:hover fieldset': {
        borderColor: '#EDF2F7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#EDF2F7',
      },
    },
  }}
/>


  <div style={{ display: 'flex', justifyContent:  'center', alignItems: 'center' }}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      style={{
        fontFamily: "'Satoshi', sans-serif",
        color: "white",
        background: '#1877F2',
        padding: "6px 10px",
        textTransform: "none",
        minWidth: mediaQueryVar == false ? '50%' : '14%',
        minHeight: mediaQueryVar == true ? "" : "50px",
        height: mediaQueryVar == true ? "" : "50px",
        marginBottom: mediaQueryVar == true ? '2%' : '',
        marginTop: mediaQueryVar == true ? '17px' : '5%',
        fontWeight: '700',
        fontSize: mediaQueryVar == true ? '18px' : '15px',
        whiteSpace: "nowrap",
        borderRadius: "23.363px",
        marginBottom:"10%"
      }}
    >
      Submit and Claim the tokens
    </Button>
  </div>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: mediaQueryVar==true?'-50px':'-60px' }}>
        {error && (
          <Alert severity="error" sx={{
            border: '2px solid red',
            color: 'red',
            backgroundColor: '#ffe6e6',
            width: 'auto',
            padding:'2px 5px',
            textAlign: 'center',
            marginTop: mediaQueryVar == true ? '' : '15%',
          }}>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{
            border: '2px solid green',
            color: 'green',
            backgroundColor: '#e6ffe6',
            width: 'auto',
            textAlign: 'center',
            marginTop: mediaQueryVar == true ? '' : '15%',
          }}>
            {successMessage}
          </Alert>
        )}
      </div>
</form>
</div>
      </div>
      
    </div>

    {mediaQueryVar === false && (
        <div
          style={{
            position:  "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "103%",
            zIndex: "1000",
            marginLeft:"-20px"
          }}
        >
          <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>{
          navigate("/portfolio-gallery");
        }
         
        }
        icon={<img src={loc === '/portfolio-gallery' ? `${window.constants.asset_path}/images/dashboardS.svg` : `${window.constants.asset_path}/images/dashboardU.svg`} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? `${window.constants.asset_path}/images/portfolioS.svg` : `${window.constants.asset_path}/images/portfolioU.svg`}  />}
      />

<BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />


    

      </BottomNavigation>
        </div>
      )}
       
  </div>

  </>
  );
};

export default CryptoForecastForm;
