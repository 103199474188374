import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import VerifiedIcon from '@mui/icons-material/Verified';
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import {ReactComponent as NotificationIcon} from "../../pages/account/profile/profile_icon/notification-icon.svg";

import IconButton from "@mui/material/IconButton";
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CreatableSelect from "react-select/creatable";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import { Tabs, Tab } from '@mui/material';
import { Helmet } from 'react-helmet';
import TableRow from "@mui/material/TableRow";
import { Button, Grid, TextField, Card, Checkbox } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { completeGoogleLogin } from "../../apihelper/login";
import CircularProgress from "@mui/material/CircularProgress";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import  CategoryPortFolioGallery  from "../home/categoryPortfolioGallery.js";
import  PracticePortfolio  from "../home/PracticePortfolio.js";
import { useLocation } from "react-router-dom";
import LoginPopUp from '../loginpopup';

import {
  
  getTotalunit,
  getFetchdropdown,
} from "../../apihelper/cryptocurrencylist";

import Dialog from "@mui/material/Dialog";
const drawerWidth = 292;
// import { useParams } from "react-router-dom";
export default function CategoryPortfolioHome(props) {
  const { categoryName } = useParams();
const formattedCategoryName = categoryName.replace(/-/g, ' ');

   
  const [selectedmenu, setselectedmenu] = useState("");

  const [options, setOptions] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
 
  const [Usablewallet, setUsablewallet] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [user_role, setuser_role] = useState("");
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  



  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { window1 } = props;
  const [alignment, setAlignment] = React.useState("left");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showloginpage, setShowloginpage] = useState(false);
 
 
  
  
  const [filter, setFilter] = useState("");
  
 
  const [loadingForLogin, setLoadingForLogin] = useState(false);

  const [loaderForSymbolLink, setLoaderForSymbolLink] = useState(false);
  const location = useLocation(); 
  const loc = location.pathname;




  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

 

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );
 
  useEffect(() => {
    setShowloginpage(true);
    const search = window.location.search;
    const params = new URLSearchParams(search);
   // console.log(params);
    if (params) {
      const googleLoginCode = params.get("code");
      if (googleLoginCode) {
        setLoadingForLogin(true);
        completeGoogleLogin(googleLoginCode).then((data) => {
         // console.log(data);
          if (data.code == "200") {
           // console.log(data.message);
            localStorage.setItem(
              "cw_portfolio_user",
              JSON.stringify(data.data)
            );
            const portfolio_userId = localStorage.getItem("portfolio_userId");
           // console.log("portfolio_UserID: ", portfolio_userId);
            if (portfolio_userId) {
             // console.log("login userId: ",portfolio_userId);
              //  navigate(`portfolio/${portfolio_userId}`, { replace: true });
              window.location.replace(
                `${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`
              );
              localStorage.removeItem("portfolio_userId");
            } else {
              if (data.data.portfolio_form_redirect === "yes") {
                //navigate("/profile", { replace: true });
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}profile`
                );
              } else {
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}home`
                );
                //navigate("/cryptocurrencylist", { replace: true });
              }
            }
          } else {
            console.log("GOOGLE LOGIN FAILED");
            // TODO
            // pop up the error
          }
        });
      } else {
        setShowloginpage(false);
      }
    }
  });




 

  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);

  const container =
    window1 !== undefined ? () => window1().document.body : undefined;
  const [selectedButton, setSelectedButton] = useState("PortfolioGallery");
  const [isTableView, setIsTableView] = useState(true);

  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };

 
  useEffect(() => {
    if (selectedButton === 'PortfolioGallery') {
      setFilter(''); // Reset the filter state when selectedButton changes to PortfolioGallery
    }
  }, [selectedButton]);

  useEffect(() => {
    if (selectedButton === 'CoinPrices') {
      navigate('/crypto-screener');
      setFilter(''); // Reset the filter state when selectedButton changes to PortfolioGallery
    }
  }, [selectedButton]);

  const [unreadAlerts, setUnreadAlerts] = useState(0);
  useEffect(() => {
  
              const userId = userData?.user_id || null;
  
              const fetchAlertsCount = async () => {
                try {
                  const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                    method: "POST", // Use POST instead of GET
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                  });
            
                  if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                  }
            
                  const data = await response.json();
                  console.log(data);
                  setUnreadAlerts(data.unread_notifications);    
  
                } catch (error) {
                  console.error("Failed to fetch alerts:", error);
                } finally {
                }
              };
            
              fetchAlertsCount();
            }
            , []);

 
  return (

    <>
    {mediaQueryVar === true ? (
    <Box
      sx={{
        flexDirection: mediaQueryVar === false ? "column" : "",

        display: "flex",
        height: "100vh",
      }}
      className="maindiv"
    >
      <Helmet>
      <title>{`Best ${formattedCategoryName} Portfolios`}</title>
</Helmet>
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div"> */}
            <img
              src="images/logo_with_bg.png"
              width={100}
              height={20}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />

            {/* </Typography> */}
          </Toolbar>
        </AppBar>
      </div>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "280px",
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
          // style={{ borderRadius:"100px"}}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          // className={classes.border_radius}
          sx={{
            display: { xs: "none", sm: "block" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "280px",
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* {loaderForSymbolLink===true && */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#EDF1F6",
          padding: "2vw",
          flexDirection: 'column',
          minHeight: '100vh', 
          }}
        className="maindiv"
      >
        {loadingForLogin && (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadingForLogin && (
          <>

<div style={{display:"flex"}}>
{mediaQueryVar === true ? (
           
           <Grid container spacing={2} alignItems="center" style={{ paddingBottom: "0px" }}>
           <Grid
             item
             xs={12}
             md={9}
             className="CryptocurrencyListHeader"
             style={{ display: 'flex', alignItems: 'center', paddingBottom: "0px" }}
           >
             <Typography
               variant="h6"
               noWrap
               component="div"
               style={{
                 textAlign: mediaQueryVar === false ? "center" : "left",
                 marginTop: mediaQueryVar === false ? "60px" : "",
               }}
             >
               {selectedButton === "CoinPrices" && (
                 <span style={{ fontSize: "24px", fontFamily: "Satoshi,sans-serif", fontWeight: "700", marginLeft: "20px" }}>
                   Add Portfolio
                   <div style={{ marginBottom: "20px" }}></div>
                 </span>
               )}
               {selectedButton === "PortfolioGallery" && (
                 <span style={{ fontSize: "24px", fontFamily: "Satoshi,sans-serif", fontWeight: "700", marginLeft: "20px" }}>
                   Best {formattedCategoryName} Portfolios
                 </span>
               )}
             </Typography>
           </Grid>
           
         </Grid>
            ) : (
              <div style={{ marginTop: "70px" }}>
                {/* Content for else condition */}
              </div>
            )}



                </div>



            <Box sx={{ 
              display: selectedButton === "PortfolioGallery"? "flex":"",
              width:"96.5%",
              
              border: "1px solid white",  
              borderRadius: "10px", 
              paddingTop: "25px",
              paddingLeft:"25px", 
              paddingRight:"-50px",
             marginTop:"10px",
             
              marginLeft:"20px", 
              marginRight: "20px", 
              boxShadow: "0 2px 4px rgba(0,0,0,0)", 
              backgroundColor: "white" ,
              overflow: "auto",
              // Hide the scrollbar
              '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'
              }}>
             
              
              <div style={{ display:"flex",marginBottom:"20px", flexDirection:"column"}}>

                

               
              <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: "20px",
                    marginTop: "5px",
                    gap:"15%"
                  }}
                >

<Box
      sx={{
        backgroundColor: "#1877F2", // Background color for the container
        paddingTop: {xs:'10px', sm:'4px'},// Padding inside the container
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", // Border radius for the container
        display: 'flex', // Make sure the box is flexible
        justifyContent: 'flex-start', // Center the content
        alignItems: 'center', // Center the content
        width: "358px",
        height:{xs:'44px', sm:'48px'},
        border:'none',
        marginLeft:"10px",
        marginTop:"0px"
      }}
    >
                  
                  <Tabs
  value={selectedButton}
  onChange={(event, newValue) => handleButtonClick(newValue)}
  aria-label="basic tabs example"
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", // Adjust border radius for more rounded corners
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', // Make sure the tabs are displayed in a row
    overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
  }}
>
  <Tab
    label="Top Portfolios"
    value="PortfolioGallery"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'16px'},
      fontStyle: "normal",
      fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
      
      backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="Add Your Portfolio"
    value="CoinPrices"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
      
      backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>




</Box>
<Grid
             item
             xs={12}
             md={3}
             className="cryptocurrencyListHeaderSearch"
             style={{ display: 'flex', justifyContent: 'flex-start', paddingBottom: "0px" }}
           >
             {selectedButton === "PortfolioGallery" && (
               <TextField
                 style={{
                   width: "100%",
                   borderRadius: "46px",
                   marginTop: mediaQueryVar ? "" : "60px",
                   fontFamily: "Satoshi, sans-serif",
                   marginRight: "25px",
                   border: "1px #1877f2 solid",
                   backgroundColor: "white",
                   paddingTop: "0px",
                   paddingBottom: "2px",
                   paddingLeft: "12px",
                   paddingRight: "30px",
                   marginBottom: "5px",
                 }}
                 size="small"
                 placeholder="Search Portfolios"
                 onChange={(e) => setFilter(e.target.value)}
                 value={filter}
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <img src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
                     </InputAdornment>
                   ),
                   style: {
                     border: "none", // Remove the border from the input
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: 'transparent', // Remove the border of the fieldset
                     },
                     '&:hover fieldset': {
                       borderColor: 'transparent', // Remove the border on hover
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: 'transparent', // Remove the border when focused
                     },
                   },
                 }}
               />
             )}
           </Grid>
{selectedButton == "CoinPrices" && (
   <TextField
   style={{
     width: "276px",
     borderRadius: "46px",
     marginTop: mediaQueryVar === true ? "" : "60px",
     fontFamily: "Satoshi, sans-serif",
     marginRight:"12px",
     border:" 1px #ECECEC solid",
     backgroundColor:"#F7F7F7",
     paddingTop:"0px",
     paddingBottom:"2px",
     paddingLeft:"12px",
     paddingRight:"30px",
     marginBottom:"10px",
     marginRight:"35px",
     fontSize:"16px",
     fontWeight:"500",
     color:"#9D9DA5"
   }}
   className="cryptocurrencyListHeaderSearch"
   size="small"
   placeholder="Search Crypto"
   onChange={(e) => setFilter(e.target.value)}
   value={filter}
   InputProps={{
     startAdornment: (
       <InputAdornment position="start">
         <img src={window.constants.asset_path  + '/images/search.svg'}
alt="search Icon" />
       </InputAdornment>
     ),
     style: {
       border: "none", // Remove the border from the input
     },
   }}
   sx={{
     '& .MuiOutlinedInput-root': {
       '& fieldset': {
         borderColor: 'transparent', // Remove the border of the fieldset
       },
       '&:hover fieldset': {
         borderColor: 'transparent', // Remove the border on hover
       },
       '&.Mui-focused fieldset': {
         borderColor: 'transparent', // Remove the border when focused
       },
     },
   }}
 />
)}

                </Grid>

                {selectedButton === "CoinPrices" ? (
              <>
               <PracticePortfolio filter={filter} onButtonClick={handleButtonClick}/>
              </>
            ) : (
              <>
                 <CategoryPortFolioGallery filter={filter} onButtonClick={handleButtonClick}/>
                 </>
              
            )} 
                
                </div>
             
           
           
           </Box>
           
            
          
          </>
        )}
        

        {loaderForSymbolLink === true && (
          <div>
            <CircularProgress
              style={{ position: "fixed", top: "40vh", left: "45vw" }}
            />
          </div>
        )}
        
      </Box>
      
      {/* } */}
     
   
     
    </Box>

      ):(

<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Ensures it takes at least the full viewport height
    flexGrow: 1, // Allows the Box to grow and fill the available space
    overflowY: "auto", 
    
  }}
>
<Helmet>
<title>{`Best ${formattedCategoryName} Portfolios`}</title>
</Helmet>
  <CssBaseline />
  
  <Box
    component="nav"
    sx={{
      width: "100%", // Take full width on mobile
    }}
    aria-label="mailbox folders"
  >
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth, // Adjust width for mobile
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>
  </Box>

  <Box
    component="main"
    sx={{
      minHeight:"100vh",
      flexGrow: 1,
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 3,
      paddingRight: 0,
      backgroundColor: "white",
      paddingBottom: "2vw",
      marginRight: "6px",
      overflow: "auto", // Allow scrolling
    }}
    className="maindiv"
  >
    {loadingForLogin && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <CircularProgress />
      </div>
    )}

    {!loadingForLogin && (
      <>
        <div style={{ 
          display: "flex", 
          alignItems: "center", 
          justifyContent: "space-between", 
          marginTop: "0px", 
          marginBottom: "5px",
        }}>
          <Typography
            variant="h6"
            component="div"
            style={{
              fontFamily: "Satoshi, sans-serif",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            <span style={{
              backgroundColor:"#1877f2",
              padding:"4px 4px",
              borderRadius:"5px",
              color:"white"
            }}> Best {formattedCategoryName}</span> Portfolios
          </Typography>

          <div style={{display:"flex", alignItems:"center"}}>
                                   <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <img src={window.constants.asset_path  + '/images/menubar.svg'}
            alt="Menu Icon" style={{ width: "24px", height: "24px", marginRight:"17px" }} />
          </IconButton></div>
        </div>

{selectedButton === "PortfolioGallery" ? (
               <TextField
                 style={{
                   width: "98%",
                   borderRadius: "46px",
                   marginTop: mediaQueryVar ? "" : "10px",
                   fontFamily: "Satoshi, sans-serif",
                   marginRight: "25px",
                   border: "1px #ECECEC solid",
                   backgroundColor: "white",
                   paddingTop: "0px",
                   paddingBottom: "2px",
                   paddingLeft: "12px",
                   paddingRight: "30px",
                   marginBottom: "5px",
                   fontSize:"14px",
                   fontWeight:"400"
                 }}
                 size="small"
                 placeholder="Search Portfolios"
                 onChange={(e) => setFilter(e.target.value)}
                 value={filter}
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <img  width={13} height={13} src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
                     </InputAdornment>
                   ),
                   style: {
                     border: "none", // Remove the border from the input
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: 'transparent', // Remove the border of the fieldset
                     },
                     '&:hover fieldset': {
                       borderColor: 'transparent', // Remove the border on hover
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: 'transparent', // Remove the border when focused
                     },
                   },
                 }}
               />
             ):
             <TextField
                 style={{
                   width: "98%",
                   borderRadius: "46px",
                   marginTop: mediaQueryVar ? "" : "10px",
                   fontFamily: "Satoshi, sans-serif",
                   marginRight: "25px",
                   border: "1px #ECECEC solid",
                   backgroundColor: "white",
                   paddingTop: "0px",
                   paddingBottom: "2px",
                   paddingLeft: "12px",
                   paddingRight: "30px",
                   marginBottom: "5px",
                   fontSize:"14px",
                   fontWeight:"400"
                 }}
                 size="small"
                 placeholder="Search Crypto"
                 onChange={(e) => setFilter(e.target.value)}
                 value={filter}
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <img width={13} height={13} src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
                     </InputAdornment>
                   ),
                   style: {
                     border: "none", // Remove the border from the input
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: 'transparent', // Remove the border of the fieldset
                     },
                     '&:hover fieldset': {
                       borderColor: 'transparent', // Remove the border on hover
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: 'transparent', // Remove the border when focused
                     },
                   },
                 }}
               />}
           


        <Grid
          container
          justifyContent="flex-end"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginBottom: "20px",
            marginTop: "17px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#1877F2", // Background color for the container
              paddingTop:"3.105px" ,// Padding inside the container
              paddingLeft:"0px" ,
              paddingRight:"0px" ,
              paddingBottom: "3.105px",
              borderRadius: "38.042px", // Border radius for the container
              display: 'inline-flex', // Make sure the box is flexible
              justifyContent: 'center', // Center the content
              alignItems: 'center', // Center the content
              width: "274px", // Full width on extra small screens
              height: "36.63px",
              border: 'none',
              marginLeft: "-12px",
            }}
          >
            <Tabs
              value={selectedButton}
              onChange={(event, newValue) => handleButtonClick(newValue)}
              aria-label="basic tabs example"
              sx={{
                marginLeft: "0px",
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                display: 'flex', // Make sure the tabs are displayed in a row
                overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
              }}
            >
              <Tab
                label="Top Portfolios"
                value="PortfolioGallery"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius:"20.962px",
                  minHeight:"31.5px",
                  height:"31.5px",
                  fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
                  marginLeft:"1px",
                  paddingLeft:"-15px",
                  marginTop:"8px",
                  border:"1px solid black",
                  marginBottom:"-5px",
                  fontSize:"13px",
                  marginRight:"-15px",
                  backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                  textTransform:"none",
                  border:"none"
                }}
              />
              <Tab
                label="Add Your Portfolio"
                value="CoinPrices"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius:"20.962px",
                  minHeight:"31px",
                  height:"31px",
                  marginLeft:"5px",
                  marginTop:"8px",
                  border:"1px solid black",
                  fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                  marginRight:"0px",
                  fontSize:"13px",
                  paddingRight:"-8px",
                  textTransform:"none",
                  backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                  border: 'none', // Remove border for tabs
                }}
              />
            </Tabs>
          </Box>
        </Grid>
        
        {selectedButton === "CoinPrices" ? (
          <PracticePortfolio filter={filter} onButtonClick={handleButtonClick} />
        ) : (
          <CategoryPortFolioGallery filter={filter} onButtonClick={handleButtonClick} />
        )}
      </>
    )}

    {loaderForSymbolLink === true && (
      <div style={{ position: "fixed", top: "40%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <CircularProgress />
      </div>
    )}
  </Box>


  {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "1000",
            marginLeft:"-10px"
          }}
        >
          <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>{
          setSelectedButton('PortfolioGallery');
          navigate("/portfolio-gallery");
        }
         
        }
        icon={<img src={loc === '/portfolio-gallery' ? `${window.constants.asset_path}/images/dashboardS.svg` : `${window.constants.asset_path}/images/dashboardU.svg`} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? `${window.constants.asset_path}/images/portfolioS.svg` : `${window.constants.asset_path}/images/portfolioU.svg`}  />}
      />
     <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
      </BottomNavigation>
        </div>
      )}
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
   
</Box>





)}
</>
);
}
