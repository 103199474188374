import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { EditText } from "react-edit-text";
import {ReactComponent as NotificationIcon} from "../pages/account/profile/profile_icon/notification-icon.svg";

import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LoginPopUp from './loginpopup';
import { Helmet } from 'react-helmet';
import { useState } from "react";
import { Button, Grid,Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import json2mq from "json2mq";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import MenuListWhitepaper from "../includes/menulistwhitepaper";



const drawerWidth = 292;

function Whitepaper(props) {
  
  const navigate = useNavigate();
  const location = useLocation(); 
  const loc = location.pathname;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [redirectpage, setRedirectpage] = useState(false);
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const [snackbarmessage, setSnackbarmessage] = React.useState("Loading...");
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  const data = [
    { label: 'Airdrops', value: '25%' },
    { label: 'Farming', value: '17%' },
    { label: 'Initial Sign-up', value: '3%' },
    { label: 'To exchanges', value: '25%' },
    { label: 'Tech and Content Team', value: '2%' },
    { label: 'Crowdwisdom360', value: '25%' },
    { label: 'Founders', value: '3%' },
];

const data1 = [
    { label: 'Starting Token Distribution – Airdrop', date: 'Sep 22, 2024' },
    { label: 'Launching Subscription Service', date: 'Oct 1, 2024' },
    { label: 'Launching Farming', date: 'Oct 10, 2024' },
    { label: 'Bonus Airdrop – 1.5x tokens', date: 'Q1 2025' },
    { label: 'Announcing Exchanges', date: 'Q2 2025' },
    { label: 'Exchange Listing', date: 'Q2 2025' }
];

const menuItems = [
  { name: "Overview", route: "overview" },
  { name: "Creator and Investor", route: "CreatorNInvestor" },
  { name: "How it works?", route: "working" },
  { name: "Tokenomics", route: "tokenomics" },
  { name: "Plan", route: "Plan" },
  { name: "Use Cases", route: "UseCases"},
  { name: "Roadmap", route: "roadmap"}

];

const [selectedSection, setSelectedSection] = useState(menuItems[0].route);

const handlePrevNext = (direction) => {
  const currentIndex = menuItems.findIndex(
    (item) => item.route === selectedSection
  );
  let newIndex = currentIndex;

  if (direction === "prev" && currentIndex > 0) {
    newIndex = currentIndex - 1;
  } else if (direction === "next" && currentIndex < menuItems.length - 1) {
    newIndex = currentIndex + 1;
  }

  setSelectedSection(menuItems[newIndex].route);
};

  const renderSection = () => {
    switch (selectedSection) {
      case 'overview':
        return (
          <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6' }}>
          <h2 style={{ color: '#000', borderBottom: '2px solid #1877F2', paddingBottom: '10px',fontWeight:700 }}>Overview</h2>
          <p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"}}>
            Tired of stressing about crypto? We get it. <strong>Crowd Wisdom</strong> simplifies your journey with portfolios and baskets that <strong>consistently outperform the market.</strong> <strong>Here's why you can trust us:</strong>
          </p>
          <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
            <li><strong>Proven track record:</strong> We've been researching crypto for 6 years and managing portfolios for 3 years.</li>
            <li><strong>Expert team:</strong> Our founders are investment pros, and our tech wizards make the platform user-friendly for everyone.</li>
            <li><strong>Millions strong:</strong> We've already helped 6 million people navigate crypto with our research. Now, we take things a step further with easy investing.</li>
          </ul>
          <p style={{ marginBottom: '20px' ,fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"}}>
          <strong>Ready to get started?</strong> Visit <a href="https://investing.crowdwisdom.live" style={{ color: '#1877F2'}}>investing.crowdwisdom.live</a> to see our winning portfolios and creators!
          </p>
        </div>        

        );
      case 'CreatorNInvestor':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#000', borderBottom: '2px solid #1877F2', paddingBottom: '10px' ,fontWeight:700}}>Creator and Investor</h2>
        <p style={{ marginBottom: '20px', fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
          Simplify your crypto journey with Crowdwisdom360.
        </p>
        <h5 style={{ color: '#000', marginBottom: '10px',color:"#7F7F80" }}>Creators:</h5>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' ,fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"}}>
          <li><strong>Share your expertise:</strong> Showcase your crypto knowledge and build a following.</li>
          <li><strong>Earn rewards:</strong> Get rewarded for your recommendations and engagement.</li>
          <li><strong>Create your own portfolio:</strong> Share your curated list of crypto assets in one place.</li>
        </ul>
        <h5 style={{ color: '#000', marginBottom: '10px',color:"#7F7F80" }}>Investors:</h5>
        <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
          <li><strong>Discover top creators:</strong> Find trusted influencers and their recommended portfolios.</li>
          <li><strong>Access AI-powered tools:</strong> Use our smart tools to analyze different options.</li>
          <li><strong>Join a community:</strong> Connect with other investors and learn from experts.</li>
        </ul>
        
      </div>;
      
      case 'working':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#000', borderBottom: '2px solid #1877F2', paddingBottom: '10px',fontWeight:700 }}>How Does the Platform Work?</h2>
        <p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
          Invest smarter with Crowd Wisdom.
        </p>
        <h5 style={{ color: '#000', marginBottom: '10px',color:"#7F7F80" }}>For Creators:</h5>
        <ul style={{ marginLeft: '20px', marginBottom: '20px' ,fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"}}>
          <li><strong>Share your expertise:</strong> Showcase your crypto knowledge and build a following.</li>
          <li><strong>Earn rewards:</strong> Get rewarded for your recommendations and engagement.</li>
          <li><strong>Create your own portfolio:</strong> Share your curated list of crypto assets in one place.</li>
          <li><strong>Promote your portfolio:</strong> Easily share your recommendations on social media.</li>
        </ul>
        <h5 style={{ color: '#000', marginBottom: '10px' ,color:"#7F7F80"}}>For Investors:</h5>
        <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
          <li><strong>Discover top portfolios: </strong>Find high-performing portfolios in various categories.</li>
          <li><strong>Analyze your options:</strong> Explore portfolio details, ROI, and creator profiles.</li>
          <li><strong>Build your watchlist:</strong> Curate a personalized list of assets to track.</li>
          <li><strong>Earn rewards:</strong> Get rewarded for participating and engaging on the platform.</li>
        </ul>
        <h5 style={{ color: '#000', marginBottom: '10px',color:"#7F7F80" }}>Everyone benefits:</h5>
        <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
          <li><strong>Easy-to-use platform:</strong> Navigate seamlessly and find what you need.</li>
          <li><strong>Rewarding experience:</strong> Earn $WISE tokens for your contributions.</li>
          <li><strong>Strong community:</strong> Connect with like-minded investors and creators.</li>
        </ul>
        <div style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
          Join us today and simplify your crypto journey.
        </div>
      </div>
      

      case 'tokenomics':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box',fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#000', borderBottom: '2px solid #1877F2', paddingBottom: '10px',fontWeight:700 }}>Tokenomics of $WISD Coins</h2>
        <p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
        As a company, we want to allocate a fair share of tokens towards – farming, airdrops and participation.        </p>
        <p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
          The chart below shows the tokenomics distribution:
        </p>
        <img src='/images/tokenomics.jpeg' alt="Tokenomics Distribution" style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }} />
  
        <div style={{ width: '100%', overflowX: 'auto', display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}>
            <thead>
              <tr>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  Allocation
                </th>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  %
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#e6e6e6' }}>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.label}
                  </td>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        <div>
        <p style={{ marginBottom: '20px', fontWeight: 'bold',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>Here are the details of each allocation:</p>

<p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
  <strong>Airdrops:</strong>
  <ul style={{ marginLeft: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
    <li><strong>Monthly rewards:</strong> Enjoy regular airdrops as a token of appreciation.</li>
    <li><strong>Boost engagement:</strong> Airdrops are designed to encourage active participation.</li>
    <li><strong>Allocation: 25%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif" ,color:"#7F7F80"}}>
  <strong>Farming:</strong>
  <ul style={{ marginLeft: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
    <li><strong>Earn tokens for your efforts:</strong> Be rewarded for engaging with the platform.</li>
    <li><strong>Continued rewards:</strong> Farming opportunities will persist even after token listing.</li>
    <li><strong>Allocation: 17%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
  <strong>Initial Sign-up:</strong>
  <ul style={{ marginLeft: '20px',color:"#7F7F80" }}>
    <li><strong>Early adopter bonus:</strong> Receive 3186 $WISD tokens as a thank you for joining early.</li>
    <li><strong>Limited-time offer: </strong>This bonus is available to the first 10,000 customers.</li>
    <li><strong>Allocation: 3%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
  <strong>Exchange Listing:</strong>
  <ul style={{ marginLeft: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
    <li><strong>Strategic allocation:</strong> 25% of tokens are reserved for exchange listing.</li>
    <li><strong>Wide accessibility:</strong> Aiming for mid-to-large size exchanges to benefit token holders.</li>
  </ul>
</p>

<p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
  <strong>Team Rewards:</strong>
  <ul style={{ marginLeft: '20px' ,fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"}}>
    <li><strong>Recognition for hard work:</strong> Tech and content team members will receive token allocations.</li>
    <li><strong>Company appreciation:</strong> Their contributions are integral to Crowdwisdom360's success.</li>
    <li><strong>Allocation: 2%</strong></li>
  </ul>
</p>

<p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
  <strong>Company Reserves:</strong>
  <ul style={{ marginLeft: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
    <li>Strategic allocation: 25% of tokens are reserved for the company's account.</li>
    <li>Financial stability: Funds will be used for working capital and reserves.</li>
  </ul>
</p>

<p style={{ marginBottom: '20px' ,fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"}}>
  <strong>Founders' Share:</strong>
  <ul style={{ marginLeft: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80" }}>
    <li><strong>Recognition for leadership:</strong> Founders will receive a combined 3% allocation.</li>
    <li><strong>Full-time commitment: </strong>Founders are actively involved in the company and accessible to the community.</li>
  </ul>
</p>

<p style={{ 
  marginBottom: '20px', 
  fontWeight: 'bold',
  border:'1px solid #1877F2',
  textAlign:'center',
 
  }}>Total Token in Circulation: 1.02 Billion</p>

</div>
      </div>;

      case 'Plan':
        return <div style={{ padding: '5%', backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '100%', boxSizing: 'border-box', fontFamily: "Satoshi, Arial, sans-serif", color: '#333', lineHeight: '1.6', marginTop: '30px' }}>
        <h2 style={{ color: '#000', borderBottom: '2px solid #1877F2', paddingBottom: '10px',fontWeight:700 }}>Our Plan</h2>
        <div style={{ width: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}>
            <thead>
              <tr>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  Milestone
                </th>
                <th style={{ backgroundColor: '#333', color: '#fff', padding: '12px 15px', textAlign: 'left', fontWeight: 'bold' }}>
                  Timeline
                </th>
              </tr>
            </thead>
            <tbody>
              {data1.map((row, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#e6e6e6' }}>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.label}
                  </td>
                  <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {row.date}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>;

case 'UseCases':
  return (
    <div
      style={{
        padding: '5%',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        maxWidth: '100%',
        boxSizing: 'border-box',
        fontFamily: "Satoshi, Arial, sans-serif",
        color: '#333',
        lineHeight: '1.6',
        marginTop: '30px',
      }}
    >
      <h2 style={{ color: '#000', borderBottom: '2px solid #16a085', paddingBottom: '10px',fontWeight:700 }}>
        Use Cases of $WISD Token
      </h2>
      <p style={{ marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"  }}>
        How $WISD Tokens Power Crowdwisdom360:
      </p>

      <h5 style={{ color: '#2c3e50', marginBottom: '10px',color:"#7F7F80" }}>1. Purchase Subscription Services:</h5>
      <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"  }}>
        <li><strong>Access premium content:</strong> Use $WISD to subscribe to portfolios and creators.</li>
        <li><strong>Enjoy discounts:</strong> Benefit from token-based discounts on subscription fees.</li>
      </ul>

      <h5 style={{ color: '#2c3e50', marginBottom: '10px',color:"#7F7F80" }}>2. Participate in Voting:</h5>
      <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"  }}>
        <li><strong>Influence the community: </strong>Vote for your favorite portfolios and creators.</li>
        <li><strong>Shape the platform: </strong>Help determine leaderboard rankings and best practices.</li>
      </ul>

      <h5 style={{ color: '#2c3e50', marginBottom: '10px',color:"#7F7F80" }}>3. Access Exclusive Content:</h5>
      <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"  }}>
        <li><strong>Discover premium offerings:</strong> Gain access to creators' exclusive content.</li>
        <li><strong>Pay with $WISD: </strong>Use your tokens to unlock premium features.</li>
      </ul>

      <h5 style={{ color: '#2c3e50', marginBottom: '10px',color:"#7F7F80" }}>4. Earn Additional Income (Creators):</h5>
      <ul style={{ marginLeft: '20px', marginBottom: '20px',fontSize:"17px" ,fontFamily:"Satoshi, sans-serif",color:"#7F7F80"  }}>
        <li><strong>Monetize your expertise:</strong> Charge for access to your recommendations.</li>
        <li><strong>Earn $WISD:</strong> Receive direct income and affiliate commissions.</li>
      </ul>
    </div>
  );



  case 'roadmap':
  return (
    <div
      style={{
        padding: '5%',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        maxWidth: '100%',
        boxSizing: 'border-box',
        fontFamily:"Satoshi, sans-serif",
        color: '#000',
        lineHeight: '1.6',
        marginTop: '30px',
        textAlign: 'center',
        position: 'relative'
      }}
    >
      <h1 style={{ color: '#000', paddingBottom: '10px',fontWeight:'700' }}>ROADMAP</h1>
      <p style={{fontSize: '17px', marginBottom: '40px', color: '#7F7F80' , fontFamily:"Satoshi, sans-serif",}}>
      At the time of launching the token we already have a fully functioning platform with creators, leaderboard and all the functionalities in place. We are launching the token with use case and platform. Our vision to launch the token is driven by creating a world-class and intense use creator economy driven platform for crypto investing. It aligns with our primary idea of ‘Crowd’s Wisdom’ which is the essence of our brand. 
      Here is the broad roadmap for the launch and services for next 12 months:       </p>

      {/* Background line in the middle */}
      <div
        style={{
          marginTop:"1%",
          position: 'absolute',
          top: '17%',
          left: mediaQueryVar?'51%':'2%',
          height: '100%',
          backgroundSize: 'cover',
          transform: 'translateX(-50%)',
          zIndex: 1000
        }}
      >
        <img
    src="/images/Background.svg" width={"200px"} height={"auto"}
    alt="Background Line"
    style={{
      position: 'absolute',
      transform: 'translateX(-50%)',
      zIndex: 1000,
      marginLeft:'10px',
      marginRight:'10px',
      objectFit: 'cover' // Ensure the image covers the height if necessary
    }}
  />
  <div style={{
    position:"absolute",
    background: "linear-gradient(to bottom, #30C8FF, #C1EEFF)",
    top:"4%",
    width:"13px",
    marginLeft:"3px",
    minHeight:"70%",
        height:"10px"
    }}></div>
      </div>

      <div style={{ marginLeft:"15%",display: 'flex', flexDirection: 'column', alignItems: 'center' ,width:"70%"}}>
        {/* Block 1 */}
        <div style={{ marginLeft:mediaQueryVar?"":"18%",marginRight:"15%",display: 'flex', flexDirection: mediaQueryVar ? 'row' : 'column', alignItems: 'left', marginBottom: '50px', width: '100%' }}>
          <div style={{ flex: 1, order: 1, paddingRight: mediaQueryVar ? '20px' : '0', textAlign: mediaQueryVar ? 'left' : 'left' }}>
            
            
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'left' }}>
            <div style={{
              backgroundColor: '#1D44FF',
              color: '#fff',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              
            }}>1</div>
          </div>
            <h3 style={{ margin: '10px 0', color: '#000',fontWeight:700 }}>Phase 1</h3>
            <p style={{ fontSize: '16px',color: '#7F7F80' ,fontFamily:"Satoshi, sans-serif",}}>
            Our primary focus is going to be on lauching subscriptions, farming and airdrop program. Second is maximising the token holders and platform visitors to create a customer base
            <br />
            </p>
          </div>
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'left' }}>
            <div style={{
              backgroundColor: '#f9f9f9',
              color: '#f9f9f9',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: mediaQueryVar ? '0 auto 0 auto' : '20px auto'
            }}>1</div>
          </div>
        </div>

        {/* Block 2 */}
        <div style={{ marginLeft: mediaQueryVar?"20%":"0",display: 'flex', flexDirection:mediaQueryVar ? 'row-reverse' : 'column', alignItems: 'center', marginBottom: '50px', width: '100%' }}>
          <div style={{ flex: 1, order: 1, paddingLeft: mediaQueryVar ? '20px' : '0', textAlign: mediaQueryVar ? 'left' : 'left' }}>
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'left' }}>
            <div style={{
              backgroundColor: '#1877F2',
              color: '#fff',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              
            }}>2</div></div>
            <h3 style={{ margin: '10px 0', color: '#000',fontWeight:700 }}>Phase 2</h3>
            <p style={{ fontSize: '16px',color: '#7F7F80', fontFamily:"Satoshi, sans-serif", }}>
            Starting Q1 of 2025, the second phase will include focus on creators program, exchange listing, launching community. It will also focus on planning to launch a validation protocol for our creators which will create one more use case for our token and is one of a kind
            <br />
            </p>
          </div>
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'center' }}>
            <div style={{
              backgroundColor: '#f9f9f9',
              color: '#f9f9f9',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: mediaQueryVar ? '0 auto 0 auto' : '20px auto'
            }}>2</div>
          </div>
        </div>

        {/* Block 3 */}
        <div style={{ marginLeft:mediaQueryVar?"":"18%",marginRight:"20%",display: 'flex', flexDirection: mediaQueryVar? 'row' : 'column', alignItems: 'center', marginBottom: '50px', width: '100%' }}>
          <div style={{ flex: 1, order: 1, paddingRight: mediaQueryVar ? '20px' : '0', textAlign: mediaQueryVar ? 'left' : 'left' }}>
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'left' }}>
            <div style={{
              backgroundColor: '#1877F2',
              color: '#fff',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              
            }}>3</div></div>
            <h3 style={{ margin: '10px 0', color: '#000',fontWeight:700 }}>Phase 3</h3>
            <p style={{ fontSize: '16px',color: '#7F7F80', fontFamily:"Satoshi, sans-serif", }}>
            This will be executed after listing in Q2 2025. Our focus will be to scale the products and offerings which we currently are providing. It will also focus on multiplying token holder value and making more opportunities for revenue avenues.  
            <br />
            </p>
          </div>
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'center' }}>
            <div style={{
              backgroundColor: '#f9f9f9',
              color: '#f9f9f9',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: mediaQueryVar ? '0 auto 0 auto' : '20px auto'
            }}>3</div>
          </div>
        </div>

        {/* Block 4 */}
        <div style={{ marginLeft:mediaQueryVar?"20%":"0",display: 'flex', flexDirection: mediaQueryVar ? 'row-reverse' : 'column', alignItems: 'center', marginBottom: '50px', width: '100%' }}>
          <div style={{ flex: 1, order: 1, paddingLeft: mediaQueryVar ? '20px' : '0', textAlign: mediaQueryVar ? 'left' : 'left' }}>
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'left' }}>
            <div style={{
              backgroundColor: '#1877F2',
              color: '#fff',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              
            }}>4</div></div>
            <h3 style={{ margin: '10px 0', color: '#000',fontWeight:700 }}>Phase 4</h3>
            <p style={{ fontSize: '16px',color: '#7F7F80', fontFamily:"Satoshi, sans-serif", }}>
            Our focus Q4 2025 onwards will be on partnerships to expand and close loop on moving from investment products to executing investment portfolios directly. 
            <br />
            </p>
          </div>
          <div style={{ flex: 1, order: 2, position: 'relative', textAlign: 'center' }}>
            <div style={{
              backgroundColor: '#f9f9f9',
              color: '#f9f9f9',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: mediaQueryVar ? '0 auto 0 auto' : '20px auto'
            }}>4</div>
          </div>
        </div>
      </div>
    </div>
  );



      default:
        return <div>Select a section</div>;
    }
  };
  
  
  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbaropen(false);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <MenuListWhitepaper
        onSelectSection={setSelectedSection}
        selectedSection={selectedSection}
      />
    </div>
  );

  const currentIndex = menuItems.findIndex(
    (item) => item.route === selectedSection
  );
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );




  const container =
    window !== undefined ? () => window().document.body : undefined;

    const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);


  return (
    <>
    {mediaQueryVar === true? (
    <Box sx={{ display: "flex", flexWrap: "wrap", backgroundColor:"#EDF1F6" }} className="main_account">
      <Helmet>
        <title>Whitepaper</title>
      </Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <div
          style={{
            backgroundColor: "#EDF1F6",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }} >
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                
              </Grid>
            </Grid>
          </Box>

          <Box
           
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            
            <Grid container spacing={2}>
             

<Box style ={{
 backgroundColor:"white",
 marginTop:"18px",
 marginLeft:"18px",
 marginRight:"18px",
 padding:"32px",
 borderRadius:"10px",
 width:"100%"
}}>

{renderSection()}

<div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  }}
>
  <button
    onClick={() => handlePrevNext("prev")}
    disabled={currentIndex === 0}
    style={{
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "600",
      color: currentIndex === 0 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === 0 ? "#f0f0f0" : "white",
      border: currentIndex === 0 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === 0 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
    }}
  >
    &#8592; {currentIndex > 0 ? menuItems[currentIndex - 1].name : ""}
  </button>
  <button
    onClick={() => handlePrevNext("next")}
    disabled={currentIndex === menuItems.length - 1}
    style={{
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "600",
      color: currentIndex === menuItems.length - 1 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === menuItems.length - 1 ? "#f0f0f0" : "white",
      border: currentIndex === menuItems.length - 1 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === menuItems.length - 1 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
    }}
  >
    {currentIndex < menuItems.length - 1 ? menuItems[currentIndex + 1].name : ""} &#8594;
  </button>
</div>




      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={severity}
      />

 </Box>

            </Grid>
          </Box>
        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
    </Box>
    ):(
      <Box
sx={{
display: "flex",
flexDirection: "column",
minHeight: "100vh", // Ensures it takes at least the full viewport height
flexGrow: 1, // Allows the Box to grow and fill the available space
overflowY: "auto", 
padding:1

}}
>
<Helmet>
        <title>Whitepaper</title>
</Helmet>
<CssBaseline />

<Box
component="nav"
sx={{
  width: "100%", // Take full width on mobile
}}
aria-label="mailbox folders"
>
<Drawer
  container={container}
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true,
  }}
  sx={{
    "& .MuiDrawer-paper": {
      width: drawerWidth, // Adjust width for mobile
      backgroundColor: "white",
      color: "#727376",
    },
  }}
>
  {drawer}
</Drawer>
</Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 1,
          paddingBottom: 3,
          
          paddingLeft: 1,
          paddingRight: 0,
          backgroundColor: "white",
          paddingBottom: "2vw",
          marginRight: "6px",
          overflow: "auto", // Allow scrolling
        }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
           
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "0px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    style={{
                      fontFamily: "Satoshi, Arial, sans-serif",
                      marginTop:"-5px",
                      marginLeft:"-5px",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    Whitepaper
                  </Typography>
                  {mediaQueryVar? (
 <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
 <NotificationIcon className="notification-icon fa-2x" />
 <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
       {unreadAlerts}
 </span>
</div>
):
<div style={{display:"flex"}}>
              <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>  
              
              <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src= '/images/menubar.svg'
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"10px",marginTop:"0px" }} />
  </IconButton></div>
}
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
            
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            {renderSection()} 
    
          </Box>

          <div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    padding: "8px 16px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    marginBottom:"80px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  }}
>
  <button
    onClick={() => handlePrevNext("prev")}
    disabled={currentIndex === 0}
    style={{
      flex: 1,
      padding: "8px 12px",
      fontSize: "14px",
      fontWeight: "600",
      color: currentIndex === 0 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === 0 ? "#f0f0f0" : "white",
      border: currentIndex === 0 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === 0 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
      textAlign: "center",
      marginRight: "8px",
      whiteSpace: "nowrap", // Ensures text doesn't wrap to the next line
      overflow: "hidden",
      textOverflow: "ellipsis", // Handles overflow gracefully
    }}
  >
    &#8592; {currentIndex > 0 ? menuItems[currentIndex - 1].name : ""}
  </button>
  <button
    onClick={() => handlePrevNext("next")}
    disabled={currentIndex === menuItems.length - 1}
    style={{
      flex: 1,
      padding: "8px 12px",
      fontSize: "14px",
      fontWeight: "600",
      color: currentIndex === menuItems.length - 1 ? "#ccc" : "#1877F2",
      backgroundColor: currentIndex === menuItems.length - 1 ? "#f0f0f0" : "white",
      border: currentIndex === menuItems.length - 1 ? "1px solid #ccc" : "1px solid #1877F2",
      borderRadius: "5px",
      cursor: currentIndex === menuItems.length - 1 ? "not-allowed" : "pointer",
      transition: "all 0.3s ease",
      outline: "none",
      textAlign: "center",
      marginLeft: "8px",
      whiteSpace: "nowrap", // Ensures text doesn't wrap to the next line
      overflow: "hidden",
      textOverflow: "ellipsis", // Handles overflow gracefully
    }}
  >
    {currentIndex < menuItems.length - 1 ? menuItems[currentIndex + 1].name : ""} &#8594;
  </button>
</div>

        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
      <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginLeft:"-10px"
          }}
        >

      <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
    </BottomNavigation>
        </div>
        {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </Box>

    

)}
</>


  );
  
 
}
  
export default Whitepaper;
