import "./privacyPolicy.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import {ReactComponent as NotificationIcon} from "../../pages/account/profile/profile_icon/notification-icon.svg";

import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist.jsx";
import MenuListHome from "../../includes/menuListHome.jsx";
import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Grid } from "@mui/material";
import { getContent } from "../../apihelper/content";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import LoginPopUp from '../loginpopup';

const drawerWidth = 292;
// const useStyles = makeStyles({
//   border_radius: {
//     borderRadius: 3,
//     backgroundColor: "red",
//   },
// });

function PrivacyPolicy(props) {
  // const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [privacydata, setPrivacydata] = useState("");
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  const [loader, setLoader] = useState(false);
  const location = useLocation(); 
  const loc = location.pathname;
  const userData1 = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const [userData,setUserData]=useState(undefined)
  React.useEffect(() => {
    const localuserdata=localStorage.getItem("cw_portfolio_user")
    setUserData(localuserdata)
    getContent("policy").then((data) => {
      if (data.code === "200") {
        setPrivacydata(data.content);
       // console.log(privacydata);
        setLoader(true);
      } else {
        console.log("Api Call Error");
        // Todo pop up the error
      }
    });
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
     <SidebarMenuList/>
    </div>
  );

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }


  const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData1?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Helmet>
        <title>Privacy Policy</title>
</Helmet>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          // style={{ borderRadius:"100px"}}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          // className={classes.border_radius}
          sx={{
            display: { xs: "none", sm: "block" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
       
        <Box sx={{ flexGrow: 1, margin: "2px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
            <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" ,
  marginLeft:"5px"
}}>

                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  style={{
                    fontFamily: "Satoshi, sans-serif",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "600",
                  }}
                >
                  Privacy Policy
                </Typography>
                {mediaQueryVar? (
 <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
 <NotificationIcon className="notification-icon fa-2x" />
 <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
       {unreadAlerts}
 </span>
</div>
):
<div style={{display:"flex"}}>
              <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>  
              
              <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src= '/images/menubar.svg'
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"10px",marginTop:"0px" }} />
  </IconButton></div>
}
</div>
            </Grid>

            {/* <Grid item xs={6}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Typography color="text.primary">Home</Typography>
                                <Typography color="text.primary">Privacy Policy</Typography>

                            </Breadcrumbs>

                        </Grid> */}
            <Grid item xs={12} align="left">
              {loader === false && (
                <Stack>
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={60}
                    style={{ marginBottom: "10px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={60}
                    style={{ marginBottom: "10px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={"50%"}
                    height={60}
                    style={{ marginBottom: "10px" }}
                  />
                </Stack>
              )}
              <div dangerouslySetInnerHTML={{ __html: privacydata }} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "105%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-10px"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
   <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
</div>
)}
{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      
    </Box>
  );
}

export default PrivacyPolicy;
